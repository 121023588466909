import React from "react";
import { connect } from "react-redux";
import {
  userActions,
  permissionActions,
  inventoryActions,
  createProfileActions,
} from "../_actions";
import NumberFormat from "react-number-format";
import { config } from "../_helpers";
import { toast } from "react-toastify";
import ReactModal from "react-modal";
import { unAvailableReason } from "../_utils/utils";
import ViewExpiryAndAssignedDates from "_components/ViewExpiryAndAssignedDates";
import { userConstants } from "_constants";
import ViewProfile from "_components/Profile/ViewProfile";
import { formatDate } from "_helpers/helpers";
import { drop } from "lodash";

function formatPhoneNumber(s) {
  var s2 = ("" + s).replace(/\D/g, "");
  var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
  return !m ? null : m[1] + "-" + m[2] + "-" + m[3];
}

const UserRoles = {
  admin: 1,
  supAdmin: 2,
};

const PERMISSION_FOR_EXPIRE_ASSIGNED_DATE = "Manage Expiry/Assigned Dates";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alertOpen: false,
      generalQualificationList: [],
      notes: "",
      profile: [],
      inventoryFlag: true,
      count: 0,
      permList: [],
      isGuest: false,
      unavailableReason: 0,
      is_locked: "",
      otherReason: "",
      active: 1,
    };
    this.goBack = this.goBack.bind(this);
    this.ProfilePic = this.ProfilePic.bind(this);
    this.editProfile = this.editProfile.bind(this);
    this.updateNotes = this.updateNotes.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onImageError = this.onImageError.bind(this);
    this.alertCloseModal = this.alertCloseModal.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(permissionActions.getPermission());
    this.props.dispatch(createProfileActions.getAll());
    if (window.location.pathname.includes("profile")) {
      const url = window.location.href;
      const id = url.split("/").pop();
      let data = new FormData();
      data.append("hash", id);
      this.props.dispatch(inventoryActions?.getInventoryList(data));
    } else if (!!this.props?.user?.profile?.data?.hash) {
      let data = new FormData();
      data.append("hash", this.props?.user?.profile?.data?.hash);
      this.props.dispatch(inventoryActions?.getInventoryList(data));
    }
  }

  handleChange(event) {
    if (event.target.name === "notes") {
      if (this.state.permList.includes("Employee Notes")) {
        this.setState({
          [event.target.name]: event.target.value,
          template: "custommail",
        });
      }
      return;
    }
    this.setState({
      [event.target.name]: event.target.value,
      template: "custommail",
    });
  }

  updateNotes(hash) {
    let data = new FormData();
    data.append("hash", hash);
    data.append("note", this.state.notes);
    if (this.state.notes !== null) {
      this.setState({ savingNote: true });
      this.props
        .dispatch(userActions.updateEmployeeNote(data, "?hash=" + hash))
        .then(
          (res) => {
            toast.success("Notes Saved !");
            this.props.dispatch(userActions.loginProfile());
            this.setState({ savingNote: false });
          },
          (err) => {
            toast.error("Notes Cannot be saved");
            this.setState({ savingNote: false });
          }
        );
    }
    // this.props.dispatch(userActions.getProfile("?hash="+hash));
  }

  onImageError(e) {
    e.target.setAttribute("src", config.apiUrl + "/" + config.altImg);
  }

  alertCloseModal() {
    this.setState({ alertOpen: false }, () => {
      this?.props.dispatch({
        type: userConstants?.REMOVEPROFILE_SUCCESS,
        removeUser: null,
      });
    });
  }

  componentWillReceiveProps(nextProps) {
    const { user, inventory, permission } = nextProps;
    // debugger
    var userData,
      inventory_list = [];
    var permList = [];
    if (permission.permissions) {
      permList = permission.permissions.data;
      this.setState({
        permList,
      });
    }
    if (inventory?.inventory?.success) {
      this.setState({ inventoryFlag: false, count: this.state.count + 1 });
      if (inventory.inventory.data) {
        var total_cost = 0;
        inventory.inventory.data.map((item, index) => {
          total_cost += parseInt(item.cost);
          inventory_list.push(
            <div className="inv_item" key={index}>
              <h4 className="cp-title">Inventory Assigned</h4>
              <div className="inv_cell">
                {index + 1 + ". " + item.unique_id + " (" + item.type + ") "}

                {/*<span className="cost">{"$"+item.cost}</span>*/}
                <NumberFormat
                  className="cost"
                  value={item.cost}
                  displayType={"text"}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  thousandSeparator={true}
                  prefix={"$"}
                />
                <div className="date-status-by">
                  <p>{`Assigned on: ${formatDate(item?.assign_date)}`}</p>
                  {!!item?.assigned_by && (
                    <p>{`Assigned by: ${item?.assigned_by}`}</p>
                  )}
                  <p>
                    Status:{" "}
                    {item?.acknowledged ? (
                      <span style={{ color: "green" }}>Acknowledged</span>
                    ) : (
                      <span style={{ color: "red" }}>Not Acknowledged</span>
                    )}
                  </p>
                </div>
              </div>
              <div className="inv_cell" data-header-title="Unique Id">
                {item.unique_id}
              </div>
              <div className="inv_cell" data-header-title="Cost">
                {item.cost}
              </div>
            </div>
          );
          return inventory_list;
        });
        this.setState({ inventory_list, total_cost });
      }
    }
    if (this.props.match.path === "/profile/:hash") {
      if (user.items) {
        this.setState({ Eactive: user.items.data.active });
      }
    }

    if (user.profile) {
      this.setState({
        active: user.profile.data.active,
        user_type: user.profile.data.user_type,
        profileHash: user.profile.data.hash,
      });
    } else if (user.items) {
      this.setState({
        active: user.items.data.active,
        user_type: user.items.data.user_type,
        itemHash: user.items.data.hash,
      });
    }
    // this.setState({ active: userData.data.active})
    var generalQualificationList = [];
    if (this.props.match.path === "/profile/:hash") {
      userData = user.items;
    } else {
      if (user.profile) {
        userData = user.profile;
      } else if (user.items) {
        userData = user.items;
      }
    }
    if (userData) {
      var profile = userData.data;
      this.setState(
        {
          notes: this?.state?.notes ? this?.state?.notes : profile.notes,
          profile,
          hash: profile.hash,
          isGuest: profile.status === 3 ? true : false,
          unavailableReason: profile.unavailable_reason,
          otherReason: profile.other_reason,
          is_locked: profile.is_locked,
          active: profile.active,
        },
        function () {}
      );
      let data = new FormData();
      data.append("hash", profile.hash || this.state.hash);
      if (
        !nextProps.inventory.loading &&
        this.state.inventoryFlag &&
        this.state.count === 0
      ) {
        this.props.dispatch(inventoryActions.getInventoryList(data));
      }

      if (profile.qualification) {
        profile.qualification.map((item, index) => {
          generalQualificationList.push(
            <p className="crew-profile-detail" key={index}>
              {item}
            </p>
          );
          return generalQualificationList;
        });
      }
    }
    this.setState({
      generalQualificationList,
    });
  }

  ProfilePic() {
    this.props.ProfilePic();
  }

  goBack() {
    this.props.choosePosition(this.props.type);
  }
  editProfile() {
    this.props.editProfile();
  }

  setUnAvailableReason = (reason, other) => {
    return unAvailableReason(reason, other);
  };

  render() {
    const { permission } = this.props;
    var permList = [];
    if (permission.permissions) {
      permList = permission.permissions.data;
    }

    var profile_pic;
    if (this.state.profile && this.state.profile.profile_photo) {
      profile_pic = config.apiUrl + "/" + this.state.profile.profile_photo;
    } else {
      profile_pic = config.apiUrl + "/" + config.altImg;
    }
    if (this.state.profile) {
      return (
        <ViewProfile
          profile={this.state.profile}
          authUser={this.state.authUser}
          permList={permList}
        />
      );
    }
    return (
      <div>
        <div className="content_div settings">
          <center
            id="feedLoader"
            className={this.state.loader ? this.state.loader : ""}
          >
            <img
              alt="Loader"
              src={require("../assets/images/loader.gif").default}
            />
          </center>
        </div>
        {/* uers upate success modal */}
        <ReactModal
          isOpen={this.state.alertOpen}
          onClose={this.alertCloseModal.bind(this)}
          contentLabel="Minimal Modal Example"
          overlayClassName="alertPopup"
        >
          <div id="statusPopup" className="popupModal">
            <div className="modal-head">
              <h4 className="modal-title">{this.state.alertHead}</h4>
              {/*<img className="closeModal" onClick={this.viewCloseModal.bind(this)} src={require("../assets/images/close_green.png")} />*/}
            </div>
            <div className="modal-body">
              <p>{this.state.alertMsg}</p>
              <div className="btn_list">
                <button
                  className="button"
                  onClick={this.alertCloseModal.bind(this)}
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </ReactModal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { registering } = state.registration;
  const { permission, inventory, user } = state;
  return {
    registering,
    permission,
    inventory,
    user,
  };
}

const connectedRegisterPage = connect(mapStateToProps)(Profile);
export { connectedRegisterPage as Profile };

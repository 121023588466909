import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { store, config } from "./_helpers";
import { App } from "./App";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { QueryClient, QueryClientProvider } from "react-query";

Sentry.init({
  dsn: config.dsn,
  //release:"Firestorm App@v1.0",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

// Create a QueryClient instance
const queryClient = new QueryClient();

render(
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <App />
    </QueryClientProvider>
  </Provider>,
  document.getElementById("app")
);

import React from "react";

import { EditProfile } from "./EditProfile";

import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";

import { dateTimeConstants, dolApplicationStatus } from "_constants";

import { formatDate } from "_helpers/helpers";

import moment from "moment";



const DriverQualificationsComponent = ({
  profileData,
  refetchUser,
  isOpen,
  toggleAccordion,
}) => {
  return (
    <div
      id="driver-qualifications"
      className={`profile-data__drops ${isOpen ? "is-active" : ""}`}
    >
      <header onClick={() => toggleAccordion("#driver-qualifications")}>
        <button type="button">
          <h3>Driver Qualifications</h3>
        </button>
      </header>
      <div className="profile-data__drops-cnt">
        <ul role="list">
          <li role="listitem">
            <span>
              <b>Has Driver's License?</b>
            </span>
            <span>
              {profileData.drivers_license_check !== ""
                ? profileData.drivers_license_check
                  ? "Yes"
                  : "No"
                : ""}
            </span>
            <EditProfile
              label="Driver's License"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors) => (
                <>
                  <label>Has Driver's License?</label>
                  <select
                    {...register("drivers_license_check")}
                    defaultValue={profileData.drivers_license_check}
                    className={
                      errors.drivers_license_check && "profile-form-field-error"
                    }
                  >
                    <option value="">Select</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                  {errors.drivers_license_check && (
                    <p className="error-message">
                      {errors.drivers_license_check.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>Driver Pin</b>
            </span>
            <span>{profileData.driver_pin}</span>
            <EditProfile
              label="Driver Pin"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors) => (
                <>
                  <label>Driver Pin</label>
                  <input
                    {...register("driver_pin", {
                      maxLength: {
                        value: 10,
                        message:
                          "Length exceeds the maximum limit of 10 characters.",
                      },
                      pattern: {
                        value: /^[A-Za-z0-9]+$/, // Regex for alphabets and numbers
                        message: "Only alphabets and numbers are allowed.",
                      },
                    })}
                    defaultValue={profileData.driver_pin}
                    className={errors.driver_pin && "profile-form-field-error"}
                  />
                  {errors.driver_pin && (
                    <p className="error-message">{errors.driver_pin.message}</p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>DL/ID</b>
            </span>
            <span>{profileData.drivers_license}</span>
            <EditProfile
              label="DL/ID"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors) => (
                <>
                  <label>DL/ID</label>
                  <input
                    {...register("drivers_license", {
                      maxLength: {
                        value: 20,
                        message:
                          "Length exceeds the maximum limit of 20 characters.",
                      },
                      pattern: {
                        value: /^[A-Za-z0-9]+$/, // Regex for alphabets and numbers
                        message: "Only alphabets and numbers are allowed.",
                      },
                    })}
                    defaultValue={profileData.drivers_license}
                    className={
                      errors.drivers_license && "profile-form-field-error"
                    }
                  />
                  {errors.drivers_license && (
                    <p className="error-message">
                      {errors.drivers_license.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>Driver's License State Issued</b>
            </span>
            <span>{profileData.DLState}</span>
            <EditProfile
              label="Driver License State"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors) => (
                <>
                  <label>Driver License State</label>
                  <input
                    {...register("driver_license_state", {
                      maxLength: {
                        value: 20,
                        message:
                          "Length exceeds the maximum limit of 20 characters.",
                      },
                      pattern: {
                        value: /^[A-Za-z ]+$/,
                        message: "Only alphabets are allowed.",
                      },
                    })}
                    defaultValue={profileData.DLState}
                    className={
                      errors.driver_license_state && "profile-form-field-error"
                    }
                  />
                  {errors.driver_license_state && (
                    <p className="error-message">
                      {errors.driver_license_state.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>Driver's License Expiration Date</b>
            </span>
            <span>{profileData.dl_expiry}</span>
            <EditProfile
              label="Driver's License Expiration Date"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>Driver's License Expiration Date</label>
                  <Controller
                    name="dl_expiry"
                    control={control}
                    defaultValue={
                      profileData.dl_expiry
                        ? moment(profileData.dl_expiry).format(
                            dateTimeConstants.DATE_FORMAT
                          )
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date
                                .startOf("day")
                                .isBefore(moment().startOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : ""
                          )
                        }
                        showMonthDropdown
                        showYearDropdown
                        minDate={moment()}
                        placeholderText="Select Expiry Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.dl_expiry && (
                    <p className="error-message">{errors.dl_expiry.message}</p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>Has Commercial Driver's License?</b>
            </span>
            <span>
              {profileData.commercial_drivers_license_check !== ""
                ? profileData.commercial_drivers_license_check
                  ? "Yes"
                  : "No"
                : ""}
            </span>
            <EditProfile
              label="Commercial Driver's License"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors) => (
                <>
                  <label>Has Commercial Driver's License?</label>
                  <select
                    {...register("commercial_drivers_license_check")}
                    defaultValue={profileData.commercial_drivers_license_check}
                    className={
                      errors.commercial_drivers_license_check &&
                      "profile-form-field-error"
                    }
                  >
                    <option value="">Select</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                  {errors.commercial_drivers_license_check && (
                    <p className="error-message">
                      {errors.commercial_drivers_license_check.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>Commercial DL/ID</b>
            </span>
            <span>{profileData.commercial_drivers_license}</span>
            <EditProfile
              label="Commercial DL/ID"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors) => (
                <>
                  <label>Commercial DL/ID</label>
                  <input
                    {...register("commercial_drivers_license", {
                      maxLength: {
                        value: 20,
                        message:
                          "Length exceeds the maximum limit of 20 characters.",
                      },
                      pattern: {
                        value: /^[A-Za-z0-9]+$/, // Regex for alphabets and numbers
                        message: "Only alphabets and numbers are allowed.",
                      },
                    })}
                    defaultValue={profileData.commercial_drivers_license}
                    className={
                      errors.commercial_drivers_license &&
                      "profile-form-field-error"
                    }
                  />
                  {errors.commercial_drivers_license && (
                    <p className="error-message">
                      {errors.commercial_drivers_license.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>Commercial Driver's License Expiration Date</b>
            </span>
            <span>{profileData.commercial_dl_expiry}</span>
            <EditProfile
              label="Commercial Driver's License Expiration Date"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>Commercial Driver's License Expiration Date</label>
                  <Controller
                    name="commercial_dl_expiry"
                    control={control}
                    defaultValue={
                      profileData.commercial_dl_expiry
                        ? moment(profileData.commercial_dl_expiry).format(
                            dateTimeConstants.DATE_FORMAT
                          )
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date
                                .startOf("day")
                                .isBefore(moment().startOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : ""
                          )
                        }
                        minDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select Expiry Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.commercial_dl_expiry && (
                    <p className="error-message">
                      {errors.commercial_dl_expiry.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>DOT Drug Screening Status</b>
            </span>
            <span>{profileData.drug_screening_doc ? "Passed" : "Failed"}</span>
            <EditProfile
              label="DOT Drug Screening Status"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors) => (
                <>
                  <label>DOT Drug Screening Status</label>
                  <select
                    {...register("drug_screening_doc")}
                    defaultValue={profileData.drug_screening_doc}
                    className={
                      errors.drug_screening_doc && "profile-form-field-error"
                    }
                  >
                    <option value="1">Passed</option>
                    <option value="0">Failed</option>
                  </select>
                  {errors.drug_screening_doc && (
                    <p className="error-message">
                      {errors.drug_screening_doc.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>Insurance Denied?</b>
            </span>
            <span>
              {profileData.insurance_denied == null
                ? "-"
                : profileData.insurance_denied
                ? "Yes"
                : "No"}
            </span>
            <EditProfile
              label="Insurance Denied Status"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors) => (
                <>
                  <label>Insurance Denied?</label>
                  <select
                    {...register("insurance_denied")}
                    defaultValue={profileData.insurance_denied}
                    className={
                      errors.insurance_denied && "profile-form-field-error"
                    }
                  >
                    <option value="">Select</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                  {errors.insurance_denied && (
                    <p className="error-message">
                      {errors.insurance_denied.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>Insurance: Date Applied</b>
            </span>
            <span>{formatDate(profileData.insurance_application_date)}</span>
            <EditProfile
              label="Insurance Date Applied"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>Insurance: Date Applied</label>
                  <Controller
                    name="insurance_application_date"
                    control={control}
                    defaultValue={
                      profileData.insurance_application_date
                        ? moment(profileData.insurance_application_date).format(
                            dateTimeConstants.DATE_FORMAT
                          )
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date.endOf("day").isAfter(moment().endOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : ""
                          )
                        }
                        maxDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select Insurance Applied Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.insurance_application_date && (
                    <p className="error-message">
                      {errors.insurance_application_date.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>Insurance Check Status</b>
            </span>
            <span>
              {profileData.insurance_application_date
                ? "Sent to Insurance on " +
                  formatDate(profileData.insurance_application_date)
                : ""}
            </span>
          </li>

          <li role="listitem">
            <span>
              <b>DOL Application Status</b>
            </span>
            <span>
              {profileData.dol_application_status &&
              profileData.dol_application_date
                ? dolApplicationStatus[profileData.dol_application_status] +
                  " on " +
                  formatDate(profileData.dol_application_date)
                : ""}
            </span>
            <EditProfile
              label="DOL Application Date"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>DOL Application Status</label>
                  <select
                    {...register("dol_application_status", {
                      required: "Status is required",
                    })}
                    defaultValue={profileData.dol_application_status}
                    className={
                      errors.dol_application_status &&
                      "profile-form-field-error"
                    }
                  >
                    <option value="">Select Status</option>
                    <option value="1">Application Sent</option>
                    <option value="2">Approved</option>
                    <option value="3">Rejected</option>
                  </select>
                  {errors.dol_application_status && (
                    <p className="error-message">
                      {errors.dol_application_status.message}
                    </p>
                  )}

                  <label>Date of Action</label>
                  <Controller
                    name="dol_application_date"
                    control={control}
                    defaultValue={
                      profileData.dol_application_date
                        ? moment(profileData.dol_application_date).format(
                            dateTimeConstants.DATE_FORMAT
                          )
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date.endOf("day").isAfter(moment().endOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : ""
                          )
                        }
                        maxDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select DOL Application Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.dol_application_date && (
                    <p className="error-message">
                      {errors.dol_application_date.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>DOL Number</b>
            </span>
            <span>{profileData.dol_number}</span>
            <EditProfile
              label="DOL Number"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors) => (
                <>
                  <label>DOL Number</label>
                  <input
                    {...register("dol_number", {
                      maxLength: {
                        value: 20,
                        message:
                          "Length exceeds the maximum limit of 20 characters.",
                      },
                    })}
                    defaultValue={profileData.dol_number}
                    className={errors.dol_number && "profile-form-field-error"}
                  />
                  {errors.dol_number && (
                    <p className="error-message">{errors.dol_number.message}</p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>DOL Blue Card / MSPA Expiration Date</b>
            </span>
            <span>{formatDate(profileData.mspa_exp_date)}</span>
            <EditProfile
              label="DOL Blue Card / MSPA Expiration Date"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>DOL Blue Card / MSPA Expiration Date</label>
                  <Controller
                    name="mspa_exp_date"
                    control={control}
                    defaultValue={
                      profileData.mspa_exp_date
                        ? moment(profileData.mspa_exp_date).format(
                            dateTimeConstants.DATE_FORMAT
                          )
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date
                                .startOf("day")
                                .isBefore(moment().startOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : ""
                          )
                        }
                        minDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select DOL Blue Card / MSPA Expiration Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.mspa_exp_date && (
                    <p className="error-message">
                      {errors.mspa_exp_date.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>DOL - Fingerprint Expiration Date</b>
            </span>
            <span>
              {formatDate(profileData.useritemdates?.finger_print_expiry)}
            </span>
            <EditProfile
              label="DOL - Fingerprint Expiration Date"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>DOL - Fingerprint Expiration Date</label>
                  <Controller
                    name="finger_print_expiry"
                    control={control}
                    defaultValue={
                      profileData.useritemdates?.finger_print_expiry
                        ? moment(
                            profileData.useritemdates?.finger_print_expiry
                          ).format(dateTimeConstants.DATE_FORMAT)
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date
                                .startOf("day")
                                .isBefore(moment().startOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : ""
                          )
                        }
                        minDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select DOL - Fingerprint Expiration Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.finger_print_expiry && (
                    <p className="error-message">
                      {errors.finger_print_expiry.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>DOL - Physical Expiration Date</b>
            </span>
            <span>
              {formatDate(profileData.useritemdates?.physical_expiry)}
            </span>
            <EditProfile
              label="DOL - Physical Expiration Date"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>DOL - Physical Expiration Date</label>
                  <Controller
                    name="physical_expiry"
                    control={control}
                    defaultValue={
                      profileData.useritemdates?.physical_expiry
                        ? moment(
                            profileData.useritemdates?.physical_expiry
                          ).format(dateTimeConstants.DATE_FORMAT)
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date
                                .startOf("day")
                                .isBefore(moment().startOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : ""
                          )
                        }
                        minDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select DOL - Physical Expiration Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.physical_expiry && (
                    <p className="error-message">
                      {errors.physical_expiry.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>Has Personal License Plate Number?</b>
            </span>
            <span>
              {profileData.personal_licence_plate_number_check !== ""
                ? profileData.personal_licence_plate_number_check
                  ? "Yes"
                  : "No"
                : ""}
            </span>
            <EditProfile
              label="Personal License Plate Number"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors) => (
                <>
                  <label>Has Personal License Plate Number?</label>
                  <select
                    {...register("personal_licence_plate_number_check")}
                    defaultValue={
                      profileData.personal_licence_plate_number_check
                    }
                    className={
                      errors.personal_licence_plate_number_check &&
                      "profile-form-field-error"
                    }
                  >
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                  {errors.personal_licence_plate_number_check && (
                    <p className="error-message">
                      {errors.personal_licence_plate_number_check.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>Personal License Plate Number</b>
            </span>
            <span>{profileData.personal_licence_plate_number}</span>
            <EditProfile
              label="Personal License Plate Number"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors) => (
                <>
                  <label>Personal License Plate Number</label>
                  <input
                    {...register("personal_licence_plate_number", {
                      maxLength: {
                        value: 20,
                        message:
                          "Length exceeds the maximum limit of 20 characters.",
                      },
                    })}
                    defaultValue={profileData.personal_licence_plate_number}
                    className={
                      errors.personal_licence_plate_number &&
                      "profile-form-field-error"
                    }
                  />
                  {errors.personal_licence_plate_number && (
                    <p className="error-message">
                      {errors.personal_licence_plate_number.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>Make / Model of Personal Vehicle</b>
            </span>
            <span>{profileData.plpn_make_or_model}</span>
            <EditProfile
              label="Make / Model of Personal Vehicle"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors) => (
                <>
                  <label>Make / Model of Personal Vehicle</label>
                  <input
                    {...register("plpn_make_or_model", {
                      maxLength: {
                        value: 30,
                        message:
                          "Length exceeds the maximum limit of 30 characters.",
                      },
                    })}
                    defaultValue={profileData.plpn_make_or_model}
                    className={
                      errors.plpn_make_or_model && "profile-form-field-error"
                    }
                  />
                  {errors.plpn_make_or_model && (
                    <p className="error-message">
                      {errors.plpn_make_or_model.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default DriverQualificationsComponent;

import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { isDolApprovedLoading } from "_helpers/reduxSelector";
import { formatDate } from "_helpers/helpers";
import ReactPaginate from "react-paginate";
import { dolApplicationStatus } from "_constants";

const ASC_CLASS = "sort-arrow asc";

const DESC_CLASS = "sort-arrow desc";

const initialStyleState = {
  nameClass: ASC_CLASS,
  emailClass: ASC_CLASS,
  dateClass: ASC_CLASS,
  statusClass: ASC_CLASS,
};

const Sort = {
  Asc: "asc",
  Dsc: "desc",
};

const DolApprovedDriverReport = (props) => {
  const { data, setSortParams, fetchDolApprovedDriverReport = () => {}, lastPage } = props;

  const [{ nameClass, emailClass, dateClass, statusClass }, setStyleState] =
    useState(initialStyleState);
  const [order, setOrder] = useState(true); /** true === asc **/
  const [sortItem, setSortItem] = useState("name");
  const [page, setPage] = useState(1);

  const isLoading = useSelector((state) => isDolApprovedLoading(state));

  const handlePageChange = (newPage) => {
    const sortOrder = order ? Sort.Asc : Sort.Dsc;
    fetchDolApprovedDriverReport(newPage?.selected + 1, sortItem, sortOrder);
    setPage(newPage?.selected + 1);
  };

  const noDataAvailable = !isLoading && !data?.length;

  const sortList = (className, prevStyle, sort) => {
    const newStyle = prevStyle === ASC_CLASS ? DESC_CLASS : ASC_CLASS;
    const nextOrder = prevStyle !== DESC_CLASS ? false : true;
    setStyleState({ ...initialStyleState, [className]: newStyle });
    setOrder(nextOrder);
    setSortItem(sort);
    setSortParams(sort, nextOrder);
  };

  useEffect(() => {
    const sortOrder = order ? Sort.Asc : Sort.Dsc;
    if (sortItem) {
      fetchDolApprovedDriverReport(page, sortItem, sortOrder);
    }
  }, [sortItem, order]);

  useEffect(() => {
    setPage(0);
  }, []);

  return (
    <>
      <div className="history_lists outer_list table__scroll">
        {!isLoading ? (
          <table className="mobile_hide report_list_width_full">
            <thead>
              <tr className="report_list_table_row">
                <th
                  onClick={() =>
                    sortList("nameClass", nameClass, "name")
                  }
                >
                  <span>Name</span>
                  <span className={nameClass} />
                </th>
                <th
                  onClick={() =>
                    sortList(
                      "emailClass",
                      emailClass,
                      "email"
                    )
                  }
                >
                  <span>Email</span>
                  <span className={emailClass} />
                </th>
                <th
                  onClick={() =>
                    sortList("dateClass", dateClass, "date")
                  }
                >
                  <span>Date of Action</span>
                  <span className={dateClass} />
                </th>
                <th
                  onClick={() =>
                    sortList("statusClass", statusClass, "status")
                  }
                >
                  <span>DOL Application Status</span>
                  <span className={statusClass} />
                </th>
              </tr>
            </thead>
            <tbody className="report_list_table_tbody">
              {noDataAvailable ? (
                <tr>
                  <td colSpan="7">
                    <div className="dashboard_expiration_date_no_data">
                      No Data Found
                    </div>
                  </td>
                </tr>
              ) : (
                data?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item?.last_name + ' ' + item?.first_name}</td>
                      <td>{item?.email}</td>
                      <td>{formatDate(item?.dol_application_date)}</td>
                      <td>{item?.dol_application_status ? dolApplicationStatus[item?.dol_application_status] : '-'}</td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        ) : (
          <center id="feedLoader">
            <img
              alt="Loader"
              src={require("../assets/images/loader.gif").default}
            />
          </center>
        )}

        {/* Mobile */}
        {noDataAvailable ? (
          <div className="dashboard_expiration_date_no_data for_mobile_history_list">
            No Data Found
          </div>
        ) : (
          <DolApprovedDriverReportMobileView
            data={data}
            fetchDolApprovedDriverReport={fetchDolApprovedDriverReport}
            setSortParams={setSortParams}
          />
        )}
      </div>
      {!isLoading && data?.length ? (
        <div className="fs-pagination-wrapper-outer" key={7}>
          <div className="customised-pagination right-align">
            <ReactPaginate
              forcePage={page ? page - 1 : page}
              previousLabel={""}
              nextLabel={""}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={lastPage}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={(data) => handlePageChange(data)}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default DolApprovedDriverReport;

const DolApprovedDriverReportMobileView = ({
  data,
  fetchDolApprovedDriverReport,
  setSortParams,
}) => {
  const [order, setOrder] = useState(true); /** true === asc **/
  const [sortItem, setSortItem] = useState("name");
  const [
    {
      nameClass,
      emailClass,
      dateClass,
      statusClass,
    },
    setStyleState,
  ] = useState(initialStyleState);

  useEffect(() => {
    const sortOrder = order ? "asc" : "desc";
    if (sortItem) {
      fetchDolApprovedDriverReport(0, sortItem, sortOrder);
    }
  }, [sortItem, order]);

  const sortList = (className, prevStyle, sort) => {
    const newStyle = prevStyle === ASC_CLASS ? DESC_CLASS : ASC_CLASS;
    const nextOrder = prevStyle !== DESC_CLASS ? false : true;
    setStyleState({ ...initialStyleState, [className]: newStyle });
    setOrder(nextOrder);
    setSortItem(sort);
    setSortParams?.(sort, nextOrder);
  };

  return data?.map((item, index) => (
    <React.Fragment key={index}>
      <div className="for_mobile_history_list">
        <hr></hr>
        <div className="history_items">
          <div className="status_item">
            <div
              className="list_item"
              onClick={() => sortList("nameClass", nameClass, "name")}
            >
              <strong>Name</strong>
              <span className={nameClass} />
            </div>
            <div className="list_item">
              {item?.last_name + ' ' + item?.first_name}
            </div>
          </div>

          <div className="status_item">
            <div
              className="list_item"
              onClick={() =>
                sortList("emailClass", emailClass, "email")
              }
            >
              <strong>Email</strong>
              <span className={emailClass} />
            </div>
            <div className="list_item">{item?.email}</div>
          </div>

          <div className="status_item">
            <div
              className="list_item"
              onClick={() =>
                sortList("dateClass", dateClass, "date")
              }
            >
              <strong>Date of Action</strong>
              <span className={dateClass} />
            </div>
            <div className="list_item">{formatDate(item?.dol_application_date)}</div>
          </div>

          <div className="status_item">
            <div
              className="list_item"
              onClick={() =>
                sortList("statusClass", statusClass, "status")
              }
            >
              <strong>DOL Application Status</strong>
              <span className={statusClass} />
            </div>
            <div className="list_item">{item?.dol_application_status ? dolApplicationStatus[item?.dol_application_status] : '-'}</div>
          </div>
        </div>
      </div>
    </React.Fragment>
  ));
};

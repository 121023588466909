import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { userActions, permissionActions, inventoryActions } from "../_actions";
import { authHeader, config } from "../_helpers";
import moment from "moment";
import { toast } from "react-toastify";
import { unAvailableReason } from "../_utils/utils";
import NumberFormat from "react-number-format";
import { userConstants } from "_constants";
import ViewProfile from "_components/Profile/ViewProfile";
import Modal from "react-responsive-modal";
import ReactModal from "react-modal";
import DatePicker from "react-datepicker";
import { formatDate } from "_helpers/helpers";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hash: this.props.hash,
      statusOpen: false,
      removeOpen: false,
      mailSendOpen: false,
      email: true,
      sms: true,
      status: "",
      available: "",
      statusStep: "1",
      returnDateField: moment(),
      returnDate: "",
      message: "",
      alertPop: false,
      userName: "",
      alertOpen: false,
      isGuest: false,
      unavailableReason: 0,
      otherReason: "",
      savingNote:false,
      reloadKey: 0,
      loader: false,
    };
    this.goBack = this.goBack.bind(this);
    this.statusOpenModal = this.statusOpenModal.bind(this);
    this.statusCloseModal = this.statusCloseModal.bind(this);
    this.removeOpenModal = this.removeOpenModal.bind(this);
    this.removeCloseModal = this.removeCloseModal.bind(this);
    this.alertOpenModal = this.alertOpenModal.bind(this);
    this.alertCloseModal = this.alertCloseModal.bind(this);
    this.mailSendOpenModal = this.mailSendOpenModal.bind(this);
    this.mailSendCloseModal = this.mailSendCloseModal.bind(this);
    this.setStatusReturn = this.setStatusReturn.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.sendMail = this.sendMail.bind(this);
    this.editProfile = this.editProfile.bind(this);
    this.updateNotes = this.updateNotes.bind(this);
    this.onImageError = this.onImageError.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
  }

  statusOpenModal() {
    this.setState({ statusOpen: true, statusStep: "1" });
  }

  statusCloseModal() {
    this.setState({ statusOpen: false });
  }

  removeOpenModal() {
    this.setState({ removeOpen: true });
  }

  removeCloseModal() {
    this.setState({ removeOpen: false });
  }

  alertOpenModal() {
    this.setState({ alertOpen: true });
  }

  alertCloseModal() {
    this.setState({ alertOpen: false, redirect: "/resources" },()=>{
      this?.props.dispatch({ type: userConstants?.REMOVEPROFILE_SUCCESS, removeUser: null })
    });
  }

  mailSendOpenModal() {
    this.setState({ mailSendOpen: true });
  }

  mailSendCloseModal() {
    this.setState({ mailSendOpen: false });
  }

  handleChange(event) {
    // var permList = []
    if (event.target.name === "notes") {
      if (this.state.permList.includes("Employee Notes")) {
        this.setState({
          [event.target.name]: event.target.value,
          template: "custommail",
        });
      }
      return;
    }
    this.setState({
      [event.target.name]: event.target.value,
      template: "custommail",
    });
  }

  closeAlert() {
    this.setState({
      alertPop: false,
    });
  }

  handleCheck(event) {
    // var value = event.target.name === 'email' ? this.state.email : this.state.sms;
    // this.setState({ [event.target.name]: !value });
  }

  updateNotes(hash) {
    let data = new FormData();
    data.append("hash", hash);
    data.append("note", this.state.notes);
    if (this.state.notes !== null) {
      this.setState({ savingNote: true })
      this.props.dispatch(userActions.updateEmployeeNote(data, "?hash=" + hash)).then(
        (res) => {
          toast.success("Notes Saved !")
          this.setState({ savingNote: false })
        },
        (err) => {
          toast.error("Notes Cannot be saved")
          this.setState({ savingNote: false })
        }
      );
    }
    // this.props.dispatch(userActions.getProfile("/"+hash));
  }

  componentDidMount() {
    var hash = "?hash=" + this.state.hash;
    this.props.dispatch(userActions.getProfile(hash));
    this.props.dispatch(permissionActions.getPermission());
    let data = new FormData();
    data.append("hash", this.state.hash);
    this.props.dispatch(inventoryActions.getInventoryList(data));
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    console.log(user);
    if (user?.changeStatusSuccess && !prevProps.user?.changeStatusSuccess) {
      this.statusCloseModal();
      toast.success("Changed availability successfully");
      this.setState({ reloadKey: this.state.reloadKey + 1, loader: false });
    }

    if (user?.changeStatusFailure && !prevProps.user?.changeStatusFailure) {
      this.statusCloseModal();
      toast.error("Failed to change availability");
      this.setState({ loader: false });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { user, inventory, permission } = nextProps;
    var permList = [];
    if (permission.permissions) {
      permList = permission.permissions.data;

      this.setState({
        permList,
      });
    }
    var generalQualificationList = [],
      inventory_list = [];
    if (inventory.inventory) {
      if (inventory.inventory.data) {
        var total_cost = 0;
        inventory.inventory.data.map((item, index) => {
          total_cost += parseInt(item.cost);
          inventory_list.push(
            <div className="inv_item" key={index}>
              <div className="inv_cell">
                {index + 1 + ". " + item.unique_id + " (" + item.type + ") "}
                {/*<span className="cost">{"$"+item.cost}</span>*/}
                <NumberFormat
                  value={item.cost}
                  className="cost"
                  displayType={"text"}
                  fixedDecimalScale={true}
                  decimalScale={2}
                  thousandSeparator={true}
                  prefix={"$"}
                />
              </div>
              {/*<div className="inv_cell" data-header-title="Unique Id">{item.unique_id}</div>
                            <div className="inv_cell" data-header-title="Cost">{item.cost}</div>*/}
            </div>
          );
          return inventory_list;
        });
        this.setState({ inventory_list, total_cost });
      }
    }
    if (user.items) {
      var profile = user.items.data;
      var available = profile.active === "Available" ? true : false;
      this.setState({
        status: profile.active,
        available: available,
        notes: this?.state?.notes ? this?.state?.notes : profile.notes,
        profile,
        active: user.items.data.active,
        userName: profile.first_name,
        user_type: user.items.data.user_type,
        isGuest: profile.status === 3 ? true : false,
        unavailableReason: profile.unavailable_reason,
        otherReason: profile.other_reason,
      });
      if (profile.qualification) {
        profile.qualification.map((item, index) => {
          generalQualificationList.push(
            <p className="crew-profile-detail" key={index}>
              {item}
            </p>
          );
          return generalQualificationList;
        });
      }
    }
    this.setState({
      generalQualificationList,
    });
    if (user.removeUser) {
      // alert(user.removeUser.message);
      if (user.removeUser.success) {
        // this.setState({ redirect: "/resources" })
        this.setState({
          // alertPop: true,
          alertHead: "Success",
          alertMsg: user.removeUser.message,
          removeOpen: false,
          // alertFunction: "",
          alertOpen: true,

          // redirect: "/resources"
        });
      } else {
        var hash = "?hash=" + this.state.hash;
        this.props.dispatch(userActions.getProfile(hash));
        this.setState({ removeOpen: false });
        // alert(user.removeUser.message);
        this.setState({
          alertPop: true,
          alertHead: "Error",
          alertMsg: user.removeUser.message,
          alertFunction: "",
        });
      }
    }
    if (user.notes) {
      if (user.notes.success) {
      } else {
        // alert(user.notes.message);
        this.setState({
          alertPop: true,
          alertHead: "Success",
          alertMsg: user.notes.message,
          alertFunction: "",
        });
      }
      this.props.dispatch(userActions.getProfile("?hash=" + this.state.hash));
    }
  }

  setStatusReturn(status) {
    if (status) {
      var step = "2a";
    } else step = "2b";

    this.setState(
      {
        statusStep: step,
      },
      function () {
        this.componentWillReceiveProps(this.props);
      }
    );
  }

  setUnAvailableReason = (reason, other) => {
    return unAvailableReason(reason, other);
  };

  setStep(step) {
    this.setState({ statusStep: step }, function () {
      this.componentWillReceiveProps(this.props);
    });
  }

  handleDateChange(date) {
    this.setState({
      returnDateField: date,
      returnDate: date.format("MM/DD/YY"),
    });
  }

  changeStatus(status) {
    if (status) {
      var stat = this.state.status === "Available" ? "Unavailable" : "Available";

      var hash = "?hash=" + this.state.hash;
      let data = new FormData();
      data.append("end_date", this.state.returnDate);
      data.append("hash", this.state.hash);
      data.append("status", stat);
     
      this.props.dispatch(userActions.changeStatus(data, hash));
      this.setState({ status: stat, loader: true });
    }
  }

  remove(hash) {
    let data = new FormData();
    this.statusCloseModal();
    data.append("hash", hash);
    this.props.dispatch(userActions.removeEmployee(data));
  }

  goBack() {
    this.props.goBack(this.props.resourceDetails);
  }

  editProfile(hash) {
    this.props.editProfile(hash);
  }

  print() {
    window.print();
  }

  sendMail(hash) {
    let data = new FormData();
    this.mailSendCloseModal();
    data.append("type", "mail");
    data.append("message", this.state.message);
    data.append("user_hash", hash);

    var self = this;
    fetch(config.apiUrl + "/api/employee/sendmails", {
      headers: authHeader(),
      method: "POST",
      body: data,
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        if (data.success) {
          self.setState({
            alertPop: true,
            alertHead: "Success",
            alertMsg: JSON.parse(JSON.stringify(data.message)),
            alertFunction: "",
          });
        } else {
          self.setState({
            alertPop: true,
            alertHead: "Error",
            alertMsg: JSON.parse(JSON.stringify(data.message)),
            alertFunction: "",
          });
        }
      });
  }

  onImageError(e) {
    e.target.setAttribute("src", config.apiUrl + "/" + config.altImg);
  }

  render() {
    const { permission } = this.props;

    var permList = [];
    if (permission.permissions) {
      permList = permission.permissions.data;
    }

    if (this.state.redirect === "/resources") {
      return <Redirect to={"/dashboard"} />;
    }
      return (
        <div className="resource_container profile_container" style={{ marginTop: "60px" }}>
          <div className="page_title float">
            <h1>{this.state.profile ? this.state.profile.first_name + " " + this.state.profile.last_name : ""}</h1>
            <div className="btn_list">
              {!this?.props?.isArchivedEmployees &&
              <>
                <button className="button print" onClick={this.print.bind(this)}>
                Print
              </button>
              {permList.includes("Message Employees") && (
                <button className="button edit_resource" onClick={this.mailSendOpenModal.bind(this)}>
                  Message
                </button>
              )}
              {permList.includes("Status Update") && (
                <button className="button edit_resource" onClick={this.statusOpenModal.bind(this)}>
                  Edit Status
                </button>
              )}
              {permList.includes("Remove Employee") && (
                <button className="button edit_resource" onClick={this.removeOpenModal.bind(this)}>
                  Remove Employee
                </button>
              )}
              </>}
            </div>
          </div>
          <Modal
            id="status_popup"
            className="status_popup"
            open={this.state.statusOpen}
            onClose={this.statusCloseModal.bind(this)}
            little
          >
            <div id="statusPopup">
              <div className="modal-head">
                <h4 className="modal-title">Current Status</h4>
              </div>
              {this.state.statusStep === "1" && (
                <div className="modal-body">
                  <div className="status_step step1">
                    <label>
                      {this.state.userName + " is: "}
                      <span className="green">{this.state.status}</span>
                    </label>
                    <br />
                    <button className="button" onClick={this.setStep.bind(this, "2")}>
                      Change Current Status
                    </button>
                    <p className="step_no">1/3</p>
                  </div>
                </div>
              )}
              {this.state.statusStep === "2" && this.state.available && (
                <div className="modal-body">
                  <div className="status_step step2">
                    <label>Do you know when {this.state.userName} will return?</label>
                    <button className="button" onClick={this.setStatusReturn.bind(this, true)}>
                      Yes
                    </button>
                    <button className="button grey" onClick={this.setStatusReturn.bind(this, false)}>
                      No
                    </button>
                    <p className="step_no">2/3</p>
                  </div>
                </div>
              )}
              {this.state.statusStep === "2" && !this.state.available && (
                <div className="modal-body">
                  <div className="status_step step2">
                    <label>Make Available?</label>
                    <button className="button" onClick={this.changeStatus.bind(this, true)} disabled={this.state.loader}>
                      Yes
                    </button>
                    <button className="button grey" onClick={this.statusCloseModal.bind(this)} disabled={this.state.loader}>
                      No
                    </button>
                    <p className="step_no">2/3</p>
                  </div>
                </div>
              )}
              {this.state.statusStep === "2a" && (
                <div className="modal-body">
                  <div className="status_step step4">
                    <label>Choose date of return:</label>
                    <DatePicker
                      className="returnDateField"
                      name="returnDateField"
                      selected={this.state.returnDateField}
                      onChange={this.handleDateChange.bind(this)}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      minDate={moment()}
                    />
                    <button className="button" onClick={this.setStep.bind(this, "3")}>
                      Next
                    </button>
                    <p className="step_no">2/3</p>
                  </div>
                </div>
              )}
              {this.state.statusStep === "2b" && (
                <div className="modal-body">
                  <div className="status_step step2">
                    <label>Make Unavailable?</label>
                    <button className="button" onClick={this.changeStatus.bind(this, true)} disabled={this.state.loader}>
                      Yes
                    </button>
                    <button className="button grey" onClick={this.statusCloseModal.bind(this)} disabled={this.state.loader}>
                      No
                    </button>
                    <p className="step_no">2/3</p>
                  </div>
                </div>
              )}
              {this.state.statusStep === "3" && (
                <div className="modal-body">
                  <div className="status_step step5">
                    <label>Are you sure you want to switch {this.state.userName} to Unavailable?</label>
                    <p>
                      (returning {this.state.returnDate === "" ? moment().format("MM/DD/YYYY") : formatDate(this.state.returnDate)})
                    </p>
                    <button className="button" onClick={this.changeStatus.bind(this, true)} disabled={this.state.loader}>
                      Yes
                    </button>
                    <button className="button grey" onClick={this.statusCloseModal.bind(this)} disabled={this.state.loader}>
                      No
                    </button>
                    <p>Time off must be approved by your supervisor.</p>
                    <p className="step_no">3/3</p>
                  </div>
                </div>
              )}
            </div>
          </Modal>
          <Modal
            id="remove_popup"
            className="status_popup"
            open={this.state.removeOpen}
            onClose={this.removeCloseModal.bind(this)}
            little
          >
            <div id="removePopup">
              <div className="modal-head">
                <h4 className="modal-title">
                  Remove Employee:{" "}
                  {this.state.profile ? this.state.profile.first_name + " " + this.state.profile.last_name : ""}
                </h4>
              </div>
              <div className="modal-body">
                <label>Are you sure you want to archive this employee?</label>
                <br />
                <button className="button grey" onClick={this.removeCloseModal.bind(this)}>
                  Cancel
                </button>
                <button className="button" onClick={this.remove.bind(this, this.state.hash)}>
                  Archive
                </button>
              </div>
            </div>
          </Modal>
          <Modal
            id="email_popup"
            className="status_popup"
            open={this.state.mailSendOpen}
            onClose={this.mailSendCloseModal.bind(this)}
            little
          >
            <div id="emailPopup">
              <div className="modal-head">
                <h4 className="modal-title">Custom Message</h4>
              </div>
              <div className="modal-body">
                {/* <p><input type="checkbox" name="email" defaultValue="true" onChange={this.handleCheck.bind(this)}>Email</input></p> */}
                {/* <p><input type="checkbox" name="sms" defaultValue="true" onChange={this.handleCheck.bind(this)}>SMS</input></p> */}
                <textarea
                  name="message"
                  cols="80"
                  rows="10"
                  value={this.state.message || ""}
                  onChange={this.handleChange.bind(this)}
                />

                <p className="step_no">Signature will automatically be included.</p>
                {/*<div className="btn_list">*/}
                <button className="button grey" onClick={this.mailSendCloseModal.bind(this)}>
                  Cancel
                </button>
                <button className="button" onClick={this.sendMail.bind(this, this.state.hash)}>
                  Send
                </button>
                {/*</div>*/}
              </div>
            </div>
          </Modal>
          <ReactModal
            isOpen={this.state.alertOpen}
            onClose={this.alertCloseModal.bind(this)}
            contentLabel="Minimal Modal Example"
            overlayClassName="alertPopup"
          >
            <div id="statusPopup" className="popupModal">
              <div className="modal-head">
                <h4 className="modal-title">{this.state.head}</h4>
                {/*<img className="closeModal" onClick={this.viewCloseModal.bind(this)} src={require("../assets/images/close_green.png")} />*/}
              </div>
              <div className="modal-body">
                <p>{this.state.alertMsg}</p>
                <div className="btn_list">
                  <button className="button" onClick={this.alertCloseModal.bind(this)}>
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </ReactModal>
          <ViewProfile
            userHash={this.state?.hash}
            authUser={this.state?.user?.profile?.data?.hash}
            permList={permList}
            reloadKey={this.state.reloadKey}
         />
        </div>
      );
  }
}

function mapStateToProps(state) {
  const { user, permission, inventory } = state;
  return {
    user,
    permission,
    inventory,
  };
}

const connectedRegisterPage = connect(mapStateToProps)(Profile);
export { connectedRegisterPage as Profile };

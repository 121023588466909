import React, { useState } from "react";

import { EditProfile } from "./EditProfile";

import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";

import { formatDate, preventNegativeValueInDocs } from "_helpers/helpers";
import { dateTimeConstants } from "_constants";
import moment from "moment";
import Modal from "_components/utils/Modal/Modal";

const EmploymentHistoryComponent = ({
  profileData,
  historyData,
  isOpen,
  toggleAccordion,
}) => {
  return (
      <div
        id="employment-history"
        className={`profile-data__drops ${isOpen ? "is-active" : ""}`}
      >
        <header onClick={() => toggleAccordion("#employment-history")}>
          <button type="button">
            <h3>Employment History</h3>
          </button>
        </header>

        <div className="profile-data__drops-cnt">
          <div className="profile-data__table">
            <table>
              <thead>
                <tr>
                  <th scope="col">Start Date</th>
                  <th scope="col">End Date</th>
                  <th scope="col">Last Day Worked</th>
                  <th scope="col">Reason</th>
                </tr>
              </thead>
              <tbody>
                {historyData?.length > 0 ?
                  historyData.map((record, index) => (
                    <tr>
                      <td>{formatDate(record.start_date)}</td>
                      <td>{formatDate(record.end_date)}</td>
                      <td>{formatDate(record.last_day_worked)}</td>
                      <td>{record.reason ?? '-'}</td>
                    </tr>
                  )) : <></>}
              </tbody>
            </table>
          </div>
        </div>
      </div>
  );
};

export default EmploymentHistoryComponent;

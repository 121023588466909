import { authHeader, config } from "_helpers";
import { handleError, handleResponse } from "_helpers/helpers";
import axios from "axios";

export const profileService = {
  getRecipientsList,
  addRecipientForTor,
  deleteRecipientForTor,
  sendDDForm,
  submitDirectDepositAuthorization,
  fetchDropdownData,
  getUser,
  updateProfile,
  getTrainingData,
  getInventoryAssignedData,
  getEmploymentHistoryData,
};

function getRecipientsList(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body:data
  };
  return fetch(
    config.apiUrl + "/api/systemmanagement/sytem-notification",
    requestOptions
  ).then(handleResponse, handleError);
}

function addRecipientForTor(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body:data
  };
  return fetch(
    config.apiUrl + "/api/systemmanagement/sytem-notification/create",
    requestOptions
  ).then(handleResponse, handleError);
}

function sendDDForm(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };
  return fetch(
    config.apiUrl + "/api/direct-deposit-form/send",
    requestOptions
  ).then(handleResponse, handleError);
}

function submitDirectDepositAuthorization(data) {
  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json'
    },
    body: data,
  };
  return fetch(
    config.apiUrl + "/api/direct-deposit-form/submit",
    requestOptions
  ).then(handleResponse, handleError);
}

function deleteRecipientForTor(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body:data
  };
  return fetch(
    config.apiUrl + "/api/systemmanagement/sytem-notification/remove",
    requestOptions
  ).then(handleResponse, handleError);
}

async function fetchDropdownData() {
  const { data } = await axios.get(config.apiUrl + "/api/getlistprofile");
  return data;
}

async function getTrainingData(userId) {
  const { data } = await axios.get(`${config.apiUrl}/api/employee/training/content/${userId}`, { headers: authHeader() });
  return data;
}

async function getInventoryAssignedData(hash) {
  let formData = new FormData();
  formData.append("hash", hash);
  const { data } = await axios.post(`${config.apiUrl}/api/inventory/getcrewmemberlist`, formData, { headers: authHeader() });
  return data;
}

async function getEmploymentHistoryData(userId) {
  const { data } = await axios.get(`${config.apiUrl}/api/employee/${userId}/employment-history`, { headers: authHeader() });
  return data;
}

async function getUser(hash) {
  const { data } = await axios.get(config.apiUrl + "/api/user?hash=" + hash, { headers: authHeader() });
  return data.data;
}

async function updateProfile(userId, formData) {
  return axios.post(
    config.apiUrl + "/api/employee/" + userId + "/save",
    formData,
    { headers: authHeader() }
  )
}
import { userConstants } from '../_constants';

export function user(state = {}, action) {
    switch (action.type) {
        case userConstants.GETPROFILE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case userConstants.GETPROFILE_SUCCESS:
            return {
                ...state,
                items: action.user
            };
        case userConstants.GETPROFILE_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case userConstants.EMPSELFSTATUS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case userConstants.EMPSELFSTATUS_SUCCESS:
            return {
                ...state,
                empStatus: action.empStatus
            };
        case userConstants.EMPSELFSTATUS_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case userConstants.GETALL_REQUEST:
            return {
                ...state,
                loading: true
            };
        case userConstants.GETALL_SUCCESS:
            return {
                ...state,
                items: action.user
            };
        case userConstants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error
            };

        case userConstants.EDITPROFILE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case userConstants.EDITPROFILE_SUCCESS:
            return {
                ...state,
                saveProfile: action.saveProfile
            };
        case userConstants.EDITPROFILE_FAILURE:
            return {
                ...state,
                error: action.error
            };

        case userConstants.RESET_REQUEST:
            return {
                ...state,
                loading: true
            };
        case userConstants.RESET_SUCCESS:
            return {
                ...state,
                loading: true,
                changePwd: action.changePwd
            };
        case userConstants.RESET_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.error
            };

        case userConstants.PASSWORD_REQUEST:
            return {
                ...state,
                loading: true
            };
        case userConstants.PASSWORD_SUCCESS:
            return {
                ...state,
                newPwd: action.newPwd
            };
        case userConstants.PASSWORD_FAILURE:
            return {
                ...state,
                error: action.error
            };

        case userConstants.REMOVEPROFILE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case userConstants.REMOVEPROFILE_SUCCESS:
            return {...state,
                removeUser: action.removeUser
            };
        case userConstants.REMOVEPROFILE_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case userConstants.ASSIGNSTAFF_REQUEST:
            return {
                ...state,
                loading: true
            };
        case userConstants.ASSIGNSTAFF_SUCCESS:
            return {
                ...state,
                staff: action.user
            };
        case userConstants.ASSIGNSTAFF_FAILURE:
            return {
                ...state,
                error: action.error
            };

        case userConstants.GETSTATS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case userConstants.GETSTATS_SUCCESS:
            return {
                ...state,
                stats: action.stats
            };
        case userConstants.GETSTATS_FAILURE:
            return {
                ...state,
                error: action.error
            };

        case userConstants.WIDGET_SUCCESS:
            return {
                ...state,
                widgets: action.widgets
            };

        case userConstants.WIDGET_FAILURE:
            return {
                ...state,
                widgets: action.error
            };


        case userConstants.LOGDETAILS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case userConstants.LOGDETAILS_SUCCESS:
            return {
                ...state,
                profile: action.profile
            };
        case userConstants.LOGDETAILS_FAILURE:
            return {
                ...state,
                error: action.error
            };

        case userConstants.NOTES_REQUEST:
            return {
                ...state,
                loading: true
            };
        case userConstants.NOTES_SUCCESS:
            return {
                ...state,
                notes: action.notes
            };
        case userConstants.NOTES_FAILURE:
            return {
                ...state,
                error: action.error
            };
        case userConstants.GETBULLETIN_REQUEST:
            return {
                ...state,
                loading: true
            };
        case userConstants.GETBULLETIN_SUCCESS:
            return {
                ...state,
                bullDetail: action.bullDetail
            };
        case userConstants.GETBULLETIN_FAILURE:
            return {
                ...state,
                error: action.error
            };

        case userConstants.DELETEFILE_REQUEST:
            return {
                ...state,
                loading: true
            };
        case userConstants.DELETEFILE_SUCCESS:
            return {
                ...state,
                dltFile: action.dltFile
            };
        case userConstants.DELETEFILE_FAILURE:
            return {
                ...state,
                error: action.error
            };

        case userConstants.UPDATE_DOCS_STATUS:
            return {
                ...state,
                profile:{
                    ...state.profile,
                    data:{
                        ...state.profile.data,
                        ...action.payload
                    }
                }
            };

        case userConstants.GETBULLETIN_REQUEST:
            return {
                loading: true
            };

        // Get User Roles

        case userConstants.GET_USER_ROLES_REQUEST:
            return {
                ...state,
                isUserRolesLoading: true
            };
        case userConstants.GET_USER_ROLES_SUCCESS:
            return {
                ...state,
                userRoles: action.data,
                isUserRolesLoading: false
            };
        case userConstants.GET_USER_ROLES_ERROR:
            return {
                ...state,
                isUserRolesLoading: false,
                userRolesError: action.error
            };

            //import employees-bypass

            case userConstants.IMPORT_EMPLOYEES_REQUEST:
            return {
                ...state,
                isImportEmployeesLoading: true
            };
        case userConstants.IMPORT_EMPLOYEES_SUCCESS:
            return{
                ...state,
           importedEmployees:action.data,
           isImportEmployeesLoading: false
            }
        case userConstants.IMPORT_EMPLOYEES_ERROR:
            return {
                ...state,
                isImportEmployeesLoading: false,
                importEmployeesError: action.error
            };

            //import employees-bypass

            case userConstants.ADD_EMPLOYEE_REQUEST:
            return {
                ...state,
                isAddEmployeeLoading: true
            };
        case userConstants.ADD_EMPLOYEE_SUCCESS:
            return {
                ...state,
           //data
           isAddEmployeeLoading: false
            };
        case userConstants.ADD_EMPLOYEE_ERROR:
            return {
                ...state,
                isAddEmployeeLoading: false,
                isAddEmployeeError: action.error
            };

        //Add Qualification
        case userConstants.ADD_QUALIFICATION_REQUEST:
            return {
                ...state,
                isAddQualificationLoading: true,
            };
        case userConstants.ADD_QUALIFICATION_SUCCESS:
            return {
                ...state,
                isAddQualificationLoading: false,
                addEmployeeData: action.data
            };
        case userConstants.ADD_QUALIFICATION_ERROR:
            return {
                ...state,
                isAddQualificationLoading: false,
                isAddQualificationError: action.error
            };

        //Edit Qualification    
        case userConstants.EDIT_QUALIFICATION_REQUEST:
            return {
                ...state,
                isEditQualificationLoading: true
            };
        case userConstants.EDIT_QUALIFICATION_SUCCESS:
            return {
                ...state,
                isEditQualificationLoading: false,
                editQualificationData: action.data
            };
        case userConstants.EDIT_QUALIFICATION_ERROR:
            return {
                ...state,
                isEditQualificationLoading: false,
                 isEditQualificationError: action.error
            };

        //Update Qualification
        case userConstants.UPDATE_QUALIFICATION_REQUEST:
                return {
                ...state,
               isUpdateQualificationLoading: true
            };        
        case userConstants.UPDATE_QUALIFICATION_SUCCESS:
                return {
                ...state,
               isUpdateQualificationLoading: false,
               updateQualificationData: action.data
            };
        case userConstants.UPDATE_QUALIFICATION_ERROR:
                return {
                ...state,
               isUpdateQualificationLoading: false,
               isUpdateQualificationError: action.error
            };

        // Remove Qualification
        case userConstants.REMOVE_QUALIFICATION_REQUEST:
                return {
                ...state,
               isRemoveQualificationLoading: true
            };        
        case userConstants.REMOVE_QUALIFICATION_SUCCESS:
                return {
                ...state,
                isRemoveQualificationLoading: false,
               removeQualificationData: action.data
            };
        case userConstants.REMOVE_QUALIFICATION_ERROR:
                return {
                ...state,
                isRemoveQualificationLoading: false,
                isRemoveQualificationError: action.error
            };
        case userConstants.LOCK_PROFILE_SUCCESS:
            return {
                ...state,
                lockProfileSuccess: true,
                lockProfileFailure: false,
            };
        case userConstants.LOCK_PROFILE_FAILURE:
            return {
                ...state,
                lockProfileSuccess: false,
                lockProfileFailure: true,
            };
        case userConstants.LOCK_PROFILE_REQUEST:
            return {
                ...state,
                lockProfileFailure: false,
                lockProfileSuccess: false,
            };

        case userConstants.CHANGE_STATUS_SUCCESS:
            return {
                ...state,
                changeStatusSuccess: true,
                changeStatusFailure: false,
            };
        case userConstants.CHANGE_STATUS_FAILURE:
            return {
                ...state,
                changeStatusSuccess: false,
                changeStatusFailure: true,
            };
        case userConstants.CHANGE_STATUS_REQUEST:
            return {
                ...state,
                changeStatusFailure: false,
                changeStatusSuccess: false,
            };
        default:
            return state
    }
}
import React, { useState } from "react";

import { EditProfile } from "./EditProfile";

import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";

import { formatDate, preventNegativeValueInDocs } from "_helpers/helpers";
import { dateTimeConstants } from "_constants";
import moment from "moment";
import Modal from "_components/utils/Modal/Modal";

const InventoryAssignedComponent = ({
  profileData,
  inventoryData,
  isOpen,
  toggleAccordion,
}) => {
  const [isInventoryModalOpen, setIsInventoryModalOpen] = useState(false);

  const viewMore = () => {
    setIsInventoryModalOpen(true);
  };

  const viewLess = () => {
    setIsInventoryModalOpen(false);
  };

  return (
    <>
      {isInventoryModalOpen && (
        <Modal
          isOpen={isInventoryModalOpen}
          className="update-applicant-status-modal"
        >
          <div className="modal-head">
            <h4>Inventory Assigned</h4>
            <span onClick={viewLess}>X</span>
          </div>
          <div className="modal-body applicant-status-modal-body">
            <div className="profile-data__table">
              <table>
                <thead>
                  <tr>
                    <th scope="col">Unique ID</th>
                    <th scope="col">Category</th>
                    <th scope="col">Assigned on</th>
                    <th scope="col">Assigned by</th>
                    <th scope="col">Status</th>
                    <th scope="col">Cost</th>
                  </tr>
                </thead>
                <tbody>
                  {inventoryData?.length > 0 &&
                    inventoryData.map((inventoryItem, index) => (
                      <tr>
                        <td>{inventoryItem.unique_id}</td>
                        <td>{inventoryItem.type}</td>
                        <td>{formatDate(inventoryItem.assign_date)}</td>
                        <td>{inventoryItem.assigned_by}</td>
                        <td>
                          {inventoryItem.acknowledged
                            ? "Acknowledged"
                            : "Not Acknowledged"}
                        </td>
                        <td>${inventoryItem.cost}</td>
                      </tr>
                    ))}
                </tbody>
                <tfoot>
                  <tr>
                    <th scope="row" colSpan="3">
                      Total
                    </th>
                    <td>
                      $
                      {inventoryData?.reduce(
                        (sum, item) => sum + parseFloat(item.cost || 0),
                        0
                      )}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div className="profile-data__table-text">
              <p>
                The employee is responsible for the condition, care, and
                replacement value of issued inventory. Any issued inventory that
                is not returned, lost or stolen will be the responsibility of
                the employee. If the cost of your currently issued equipment is
                not listed above, please contact your supervisor.
              </p>
            </div>
          </div>
        </Modal>
      )}
      <div
        id="inventory-assigned"
        className={`profile-data__drops ${isOpen ? "is-active" : ""}`}
      >
        <header onClick={() => toggleAccordion("#inventory-assigned")}>
          <button type="button">
            <h3>Inventory Assigned</h3>
          </button>
        </header>

        <div className="profile-data__drops-cnt">
          <div className="profile-data__table">
            <table>
              <thead>
                <tr>
                  <th scope="col">Unique ID</th>
                  <th scope="col">Category</th>
                  <th scope="col">Status</th>
                  <th scope="col">Cost</th>
                </tr>
              </thead>
              <tbody>
                {inventoryData?.length > 0 ?
                  inventoryData.map((inventoryItem, index) => (
                    <tr>
                      <td>{inventoryItem.unique_id}</td>
                      <td>{inventoryItem.type}</td>
                      <td>
                        {inventoryItem.acknowledged
                          ? "Acknowledged"
                          : "Not Acknowledged"}
                      </td>
                      <td>${inventoryItem.cost}</td>
                    </tr>
                  )) : <></>}
              </tbody>
              <tfoot>
                <tr>
                  <th scope="row" colSpan="3">
                    Total
                  </th>
                  <td>
                    $
                    {inventoryData?.reduce(
                      (sum, item) => sum + parseFloat(item.cost || 0),
                      0
                    )}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div className="profile-data__table-text">
            <p>
              The employee is responsible for the condition, care, and
              replacement value of issued inventory. Any issued inventory that
              is not returned, lost or stolen will be the responsibility of the
              employee. If the cost of your currently issued equipment is not
              listed above, please contact your supervisor.
            </p>
          </div>
        </div>
        <footer class="footer-border profile-data__footer">
          <button onClick={viewMore} type="button">
            View More Details
          </button>
        </footer>
      </div>
    </>
  );
};

export default InventoryAssignedComponent;

import { useQuery, useMutation } from "react-query";
import React, { useState, useEffect } from "react";
import Modal from "_components/utils/Modal/Modal";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { profileService } from "_services/profile.service";

export const useUpdateProfile = (refetchUser, onSuccessCallback) => {
  return useMutation(
    ({ userId, formData }) => profileService.updateProfile(userId, formData),
    {
      onSuccess: () => {
        refetchUser();
        toast.success("Profile updated successfully!");
        onSuccessCallback();
      },
      onError: (error) => {
        toast.error(
          "Failed to update profile. " +
            (typeof error?.response?.data?.message === "string"
              ? error?.response?.data?.message
              : "")
        );
      },
    }
  );
};

export const EditProfile = ({ label, profileData, refetchUser, children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    register,
    setValue,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setValue("ssn", profileData?.ssn || ""); // Set initial SSN value
    setValue("phone", profileData?.phone || "");
    setValue("second_phone", profileData?.second_phone || "");
    setValue("emg_contact_1_phone", profileData?.emgcontact1?.phone || "");
    setValue(
      "emg_contact_1_alt_phone",
      profileData?.emgcontact1?.alt_phone || ""
    );
    setValue("emg_contact_2_phone", profileData?.emgcontact2?.phone || "");
    setValue(
      "emg_contact_2_alt_phone",
      profileData?.emgcontact2?.alt_phone || ""
    );
  }, [profileData?.ssn, setValue]);

  const EditForm = ({
    children,
    register,
    errors,
    setValue,
    control,
    handleSubmit,
    handleSave,
    handleCancel,
  }) => (
    <form onSubmit={handleSubmit(handleSave)}>
      <div>{children(register, errors, setValue, control)}</div>
      <div className="button-holder button-holder--right">
        <button className="button grey" onClick={handleCancel}>
          Cancel
        </button>
        <button className="button" type="submit">
          Save
        </button>
      </div>
    </form>
  );

  const mutation = useUpdateProfile(refetchUser, () => {
    setIsModalOpen(false); // Close the modal on success
  });

  // Compare current form data with original profile data and send only changed fields
  const handleSave = (formData) => {
    const updatedFields = {};
    // Compare each field in the formData with the profileData
    Object.keys(formData).forEach((key) => {
      if (
        formData[key] != profileData[key] ||
        (formData[key] == "" && profileData[key] == 0)
      ) {
        updatedFields[key] = formData[key];
      }
    });

    mutation.mutate({ userId: profileData.id, formData: updatedFields }); // Send only changed fields
  };

  // Function to open modal and set default form values
  const handleEdit = (fieldKey) => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false); // Close modal after saving
  };

  return (
    <>
      {profileData?.status != 0 && (
        <>
          <button
            type="button"
            className="profile-edit-button"
            onClick={handleEdit}
          >
            edit button
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <g clip-path="url(#clip0_2_1126)">
                <path
                  d="M10.4999 5.83334L8.1666 3.5M1.45825 12.5417L3.43247 12.3223C3.67367 12.2955 3.79427 12.2821 3.907 12.2456C4.007 12.2132 4.10218 12.1675 4.18994 12.1096C4.28885 12.0444 4.37465 11.9586 4.54626 11.787L12.2499 4.08334C12.8943 3.43901 12.8943 2.39434 12.2499 1.75C11.6056 1.10567 10.5609 1.10567 9.9166 1.75L2.21293 9.45366C2.04132 9.62527 1.95552 9.71107 1.89029 9.80999C1.83242 9.89774 1.78668 9.99292 1.7543 10.0929C1.71781 10.2057 1.70441 10.3263 1.67761 10.5675L1.45825 12.5417Z"
                  stroke="black"
                  stroke-opacity="0.4"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_2_1126">
                  <rect width="14" height="14" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </button>
          {isModalOpen && (
            <Modal
              isOpen={isModalOpen}
              className="update-applicant-status-modal"
            >
              <div className="modal-head">
                <h4>Edit {label}</h4>
              </div>
              <div className="modal-body applicant-status-modal-body">
                <EditForm
                  children={children}
                  register={register}
                  errors={errors}
                  setValue={setValue}
                  control={control}
                  handleSubmit={handleSubmit}
                  handleSave={handleSave}
                  handleCancel={handleCancel}
                />
              </div>
            </Modal>
          )}
        </>
      )}
    </>
  );
};

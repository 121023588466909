import React, { useRef } from "react";

import { toast } from "react-toastify";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Controller } from "react-hook-form";
import NumberFormat from "react-number-format";
import DatePicker from "react-datepicker";
import Dropzone from "react-dropzone";
import ReactModal from "react-modal";

import { userStatus } from "_constants";

import { config } from "_helpers";
import { preventNegativeValueInDocs, formatSSN, convertDateTimeToPST } from "_helpers/helpers";

import { dateTimeConstants } from "_constants";

import { EditProfile, useUpdateProfile } from "./EditProfile";

import moment from "moment";

const OverviewComponent = ({
  profileData,
  profileUserName,
  dropdownData,
  refetchUser,
  permList,
}) => {
  const [imageCropModalOpen, setImageCropModalOpen] = React.useState(false);
  const [imgPreview, setImagePreview] = React.useState(null);
  const [cropResult, setCropResult] = React.useState(
    config.apiUrl + "/" + profileData.profile_photo
  );
  const cropperRef = useRef(null);

  const mutation = useUpdateProfile(refetchUser, () => {});

  const onImageDrop = (files) => {
    // Validation
    const allowed = ["png", "jpg", "jpeg"],
      file = files[0] || null;
    if (!file) {
      return toast.error("Please select an image file.");
    }
    const name = file.name,
      extension = name.split(".").pop().toLowerCase();
    if (allowed.indexOf(extension) === -1) {
      return toast.error(
        "The file format is invalid. Allowed formats are png, jpg, jpeg."
      );
    }
    setImagePreview(files[0].preview);
    setImageCropModalOpen(true);
  };

  const imageCropModalClose = () => {
    setImageCropModalOpen(false);
  };

  const onImageError = (e) => {
    e.target.setAttribute("src", config.apiUrl + "/" + config.altImg);
    // toast.error("Failed to upload image.");
  };

  const cropImage = () => {
    const cropper = cropperRef.current?.cropper;
    cropper.getCroppedCanvas().toBlob(
      (blob) => {
        setCropResult(cropper.getCroppedCanvas().toDataURL());
        setImageCropModalOpen(false);
        let data = new FormData();
        data.append("profile_pic", blob);
        mutation.mutate({ userId: profileData.id, formData: data });
      },
      "image/jpeg",
      0.45
    );
  };

  let img_preview = "";
  if (imgPreview) {
    img_preview = imgPreview;
  } else if (profileData && profileData.profile_photo) {
    img_preview = config.apiUrl + "/" + profileData.profile_photo;
  } else {
    img_preview = config.apiUrl + "/" + config.altImg;
  }

  return (
    <div id="overview" className="profile-data_comp profile-data__user">
      <header>
        <figure
          className={
            profileData.is_locked === 1
              ? "Locked"
              : profileData.status === userStatus.Guest
              ? "Guest"
              : profileData.active
          }
        >
          {permList.includes("Update Profile Picture") && profileData.status != 0 ? (
            <Dropzone
              id="file_name"
              name="file_name"
              multiple={false}
              accept="image/*"
              onDrop={onImageDrop}
              style={{ position: "unset" }}
            >
              {cropResult && (
                <img
                  onError={onImageError}
                  className="imagePreview"
                  src={cropResult}
                  alt="cropped image"
                />
              )}
            </Dropzone>
          ) : cropResult ? (
            <img
              onError={onImageError}
              className="imagePreview"
              src={cropResult}
              alt="cropped image"
            />
          ) : (
            <img
              src={config.apiUrl + "/" + config.altImg}
              alt="User profile image"
            />
          )}
        </figure>
        <div className="profile-data__user-title">
          <h3 className={profileData.colorcode}>{profileUserName}</h3>
          <EditProfile
            label="Name"
            profileData={profileData}
            refetchUser={refetchUser}
          >
            {(register, errors) => (
              <>
                <label>First Name</label>
                <input
                  {...register("first_name", {
                    required: "First name is required",
                    maxLength: {
                      value: 100,
                      message:
                        "Length exceeds the maximum limit of 100 characters.",
                    },
                  })}
                  defaultValue={profileData.first_name}
                  className={errors.first_name && "profile-form-field-error"}
                />
                {errors.first_name && (
                  <p className="error-message">{errors.first_name.message}</p>
                )}

                <label>Middle Name</label>
                <input
                  {...register("middle_name", {
                    maxLength: {
                      value: 100,
                      message:
                        "Length exceeds the maximum limit of 100 characters.",
                    },
                  })}
                  defaultValue={profileData.middle_name}
                  className={errors.middle_name && "profile-form-field-error"}
                />
                {errors.middle_name && (
                  <p className="error-message">{errors.middle_name.message}</p>
                )}

                <label>Last Name</label>
                <input
                  {...register("last_name", {
                    required: "Last name is required",
                    maxLength: {
                      value: 100,
                      message:
                        "Length exceeds the maximum limit of 100 characters.",
                    },
                  })}
                  defaultValue={profileData.last_name}
                  className={errors.last_name && "profile-form-field-error"}
                />
                {errors.last_name && (
                  <p className="error-message">{errors.last_name.message}</p>
                )}
              </>
            )}
          </EditProfile>
        </div>
      </header>
      <div className="profile-data__list">
        <ul role="list">
          <li role="listitem">
            <span>
              <b>Title</b>
            </span>
            <span>{profileData.user_type}</span>
            <EditProfile
              label="Title"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors) => (
                <>
                  <label>Title</label>
                  <select
                    {...register("user_type_id", {
                      required: "Title is required",
                    })}
                    defaultValue={profileData.user_type_id}
                    className={
                      errors.user_type_id && "profile-form-field-error"
                    }
                  >
                    <option value="">Select Title</option>
                    {dropdownData.Roles.map((role, index) => (
                      <option key={index} value={role.id}>
                        {role.name}
                      </option>
                    ))}
                  </select>

                  {errors.user_type_id && (
                    <p className="error-message">
                      {errors.user_type_id.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>
          <li role="listitem">
            <span>
              <b>Department</b>
            </span>
            <span>{profileData.crew_name}</span>
            <EditProfile
              label="Department"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors) => (
                <>
                  <label>Department</label>
                  <select
                    {...register("crew_identifier_id", {
                      required: "Department is required",
                    })}
                    defaultValue={profileData.crew_identifier_id}
                    className={
                      errors.crew_identifier_id && "profile-form-field-error"
                    }
                  >
                    <option value="">Select Department</option>
                    {dropdownData.FireFighter.map((crew, index) => (
                      <option key={index} value={crew.id}>
                        {crew.resource_name}
                      </option>
                    ))}
                  </select>
                  {errors.crew_identifier_id && (
                    <p className="error-message">
                      {errors.crew_identifier_id.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>
          <li role="listitem">
            <span>
              <b>Firestorm ID #</b>
            </span>
            <span>{profileData.fire_id}</span>
            <EditProfile
              label="Firestorm ID #"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors) => (
                <>
                  <label>Firestorm ID #</label>
                  <input
                    type="number"
                    {...register("fire_id", {
                      required: "Firestorm ID # is required",
                      min: {
                        value: 1,
                        message: "Firestorm ID # must be a valid value",
                      },
                      validate: (value) =>
                        value.toString().length <= 7 || "Firestorm ID # must be at most 7 digits",
                      valueAsNumber: true,
                    })}
                    defaultValue={profileData.fire_id}
                    className={errors.fire_id && "profile-form-field-error"}
                    onWheel={(e) => e.target.blur()}
                    onKeyDown={preventNegativeValueInDocs}
                  />
                  {errors.fire_id && (
                    <p className="error-message">{errors.fire_id.message}</p>
                  )}
                </>
              )}
            </EditProfile>
          </li>
          <li role="listitem">
            <span>
              <b>ICA #</b>
            </span>
            <span>{profileData.ica_number}</span>
            <EditProfile
              label="ICA #"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors) => (
                <>
                  <label>ICA #</label>
                  <input
                    type="number"
                    {...register("ica_number", {
                      required: "ICA # is required",
                      min: {
                        value: 1,
                        message: "ICA # must be a valid value",
                      },
                      validate: (value) =>
                        value.toString().length <= 12 || "ICA # must be at most 12 digits",
                      valueAsNumber: true,
                    })}
                    defaultValue={profileData.ica_number}
                    className={errors.ica_number && "profile-form-field-error"}
                    onWheel={(e) => e.target.blur()}
                    onKeyDown={preventNegativeValueInDocs}
                  />
                  {errors.ica_number && (
                    <p className="error-message">{errors.ica_number.message}</p>
                  )}
                </>
              )}
            </EditProfile>
          </li>
          <li role="listitem">
            <span>
              <b>Primary Crew</b>
            </span>
            <span>{profileData.primary_crew_name}</span>
            <EditProfile
              label="Primary Crew"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors) => (
                <>
                  <label>Primary Crew</label>
                  <select
                    {...register("primary_crew_identifier_id", {
                      required: "Primary Crew is required",
                    })}
                    defaultValue={profileData.primary_crew_identifier_id}
                    className={
                      errors.primary_crew_identifier_id &&
                      "profile-form-field-error"
                    }
                  >
                    <option value="">Select Primary Crew</option>
                    {dropdownData.FireFighter.map((crew, index) => (
                      <option key={index} value={crew.id}>
                        {crew.resource_name}
                      </option>
                    ))}
                  </select>
                  {errors.primary_crew_identifier_id && (
                    <p className="error-message">
                      {errors.primary_crew_identifier_id.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>
          <li role="listitem">
            <span>
              <b>SSN</b>
            </span>
            <span>{formatSSN(profileData.ssn)}</span>
            <EditProfile
              label="SSN"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors, setValue) => (
                <>
                  <label>SSN</label>
                  <NumberFormat
                    format="###-##-####"
                    mask="_"
                    placeholder="Enter SSN"
                    value={profileData.ssn}
                    onValueChange={({ value }) => {
                      setValue("ssn", value);
                    }}
                    className={errors.ssn && "profile-form-field-error"}
                    isNumericString
                    getInputRef={register("ssn", {
                      required: "SSN is required",
                      minLength: {
                        value: 9,
                        message: "SSN must be 9 digits long",
                      },
                    })}
                    onKeyDown={preventNegativeValueInDocs}
                  />
                  {errors.ssn && (
                    <p className="error-message">{errors.ssn.message}</p>
                  )}
                </>
              )}
            </EditProfile>
          </li>
          <li role="listitem">
            <span>
              <b>Birth Date</b>
            </span>
            <span>{profileData.dob}</span>
            <EditProfile
              label="Birth Date"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>Birth Date</label>
                  <Controller
                    name="dob"
                    control={control}
                    defaultValue={
                      profileData.dob
                        ? moment(profileData.dob).format(
                            dateTimeConstants.DATE_FORMAT
                          )
                        : null
                    }
                    rules={{
                      required: "Date of Birth is required",
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) => {
                          const formattedDate =
                            date && moment().diff(moment(date), "years") >= 18
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : "";
                          onChange(formattedDate);
                        }} // Handle date change
                        showMonthDropdown
                        showYearDropdown
                        maxDate={moment().subtract(18, "years")}
                        placeholderText="Select Birth Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.dob && (
                    <p className="error-message">{errors.dob.message}</p>
                  )}
                </>
              )}
            </EditProfile>
          </li>
          <li role="listitem">
            <span>
              <b>Sex</b>
            </span>
            <span>{profileData.gender}</span>
            <EditProfile
              label="Gender"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors) => (
                <>
                  <label>Gender</label>
                  <select
                    {...register("gender", {
                      required: "Gender is required",
                    })}
                    defaultValue={profileData.gender}
                    className={errors.gender && "profile-form-field-error"}
                  >
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                  {errors.gender && (
                    <p className="error-message">{errors.gender.message}</p>
                  )}
                </>
              )}
            </EditProfile>
          </li>
        </ul>
        <div className="profile-data__note footer-border">
          <header>
            <h4>
              Latest Note{" "}
              {profileData.latest_note?.author_user
                ? "by " + profileData.latest_note.author_user.full_name
                : ""}
            </h4>
            <div className="profile-data__note-dates">
              <span>
                {profileData.latest_note && profileData.latest_note.author_user
                  ? convertDateTimeToPST(profileData.latest_note.created_at)
                  : ""}
              </span>
            </div>
          </header>
          <p>{profileData.latest_note ? profileData.latest_note.note : "-"}</p>
        </div>
      </div>
      <ReactModal
        isOpen={imageCropModalOpen}
        onClose={imageCropModalClose}
        contentLabel="Minimal Modal Example"
        overlayClassName="imageCropPopup"
      >
        <div className="modal-head">
          <img
            className="closeModal"
            onClick={imageCropModalClose}
            src={require("../../assets/images/close_green.png").default}
          />
        </div>
        <div id="edit_profile_pic_popup" className="modal-body">
          <Cropper
            ref={cropperRef}
            src={img_preview}
            style={{ height: 400, width: "100%" }}
            // Cropper.js options
            aspectRatio={16 / 16}
            guides={false}
          />
          <div className="button_list">
            <Dropzone
              id="file_name"
              className="button popup_upload"
              name="file_name"
              multiple={false}
              accept="image/*"
              onDrop={onImageDrop}
            >
              <p>Upload Here</p>
            </Dropzone>
            <button onClick={cropImage} className="button">
              Crop Image
            </button>
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

export default OverviewComponent;

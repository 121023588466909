import React from "react";

import { EditProfile } from "./EditProfile";

import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";

import { formatDate, preventNegativeValueInDocs } from "_helpers/helpers";
import { dateTimeConstants } from "_constants";
import moment from "moment";

const ifcaStatus = {
  SENT: 1,
  APPROVED: 2,
};

const IfcaContractComponent = ({
  profileData,
  refetchUser,
  isOpen,
  toggleAccordion,
}) => {
  function getIfcaStatusText(ifcaStat, ifcaDate) {
    if (!ifcaStat) return "";

    let statusText = "";
    if (ifcaStat === ifcaStatus.SENT) {
      statusText = "Sent";
    } else if (ifcaStat === ifcaStatus.APPROVED) {
      statusText = "Approved";
    }
    return `${statusText} on ${formatDate(ifcaDate)}`;
  }

  return (
    <div
      id="ifca-contract"
      className={`profile-data__drops ${isOpen ? "is-active" : ""}`}
    >
      <header onClick={() => toggleAccordion("#ifca-contract")}>
        <button type="button">
          <h3>IFCA Contract</h3>
        </button>
      </header>
      <div className="profile-data__drops-cnt">
        <ul role="list">
          <li role="listitem">
            <span>
              <b>IFCA #</b>
            </span>
            <span>{profileData.ifca_number}</span>
            <EditProfile
              label="IFCA #"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors) => (
                <>
                  <label>IFCA #</label>
                  <input
                    {...register("ifca_number", {
                      maxLength: {
                        value: 20,
                        message:
                          "Length exceeds the maximum limit of 20 characters.",
                      },
                    })}
                    defaultValue={profileData.ifca_number}
                    className={errors.ifca_number && "profile-form-field-error"}
                    onWheel={(e) => e.target.blur()}
                    onKeyDown={preventNegativeValueInDocs}
                  />
                  {errors.ifca_number && (
                    <p className="error-message">
                      {errors.ifca_number.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>
          <li role="listitem">
            <span>
              <b>IFCA FFT1</b>
            </span>
            <span>
              {getIfcaStatusText(
                profileData.ifca_fft1_status,
                profileData.ifca_fft1_date
              )}
            </span>
            <EditProfile
              label="IFCA FFT1"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>Status</label>
                  <select
                    {...register("ifca_fft1_status", {
                      required: "Status is required",
                    })}
                    defaultValue={profileData.ifca_fft1_status}
                    className={
                      errors.ifca_fft1_status && "profile-form-field-error"
                    }
                  >
                    <option value="">Select Status</option>
                    <option value="1">Sent</option>
                    <option value="2">Approved</option>
                  </select>
                  {errors.ifca_fft1_status && (
                    <p className="error-message">
                      {errors.ifca_fft1_status.message}
                    </p>
                  )}

                  <label>Date</label>
                  <Controller
                    name="ifca_fft1_date"
                    control={control}
                    defaultValue={
                      profileData.ifca_fft1_date
                        ? moment(profileData.ifca_fft1_date).format(
                            dateTimeConstants.DATE_FORMAT
                          )
                        : null
                    }
                    rules={{
                      required: "Date is required",
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date.endOf("day").isAfter(moment().endOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : ""
                          )
                        }
                        maxDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.ifca_fft1_date && (
                    <p className="error-message">
                      {errors.ifca_fft1_date.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>LSA</b>
            </span>
            <span>
              {getIfcaStatusText(profileData.lsa_status, profileData.lsa_date)}
            </span>
            <EditProfile
              label="LSA"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>Status</label>
                  <select
                    {...register("lsa_status", {
                      required: "Status is required",
                    })}
                    defaultValue={profileData.lsa_status}
                    className={errors.lsa_status && "profile-form-field-error"}
                  >
                    <option value="">Select Status</option>
                    <option value="1">Sent</option>
                    <option value="2">Approved</option>
                  </select>
                  {errors.lsa_status && (
                    <p className="error-message">{errors.lsa_status.message}</p>
                  )}

                  <label>Date</label>
                  <Controller
                    name="lsa_date"
                    control={control}
                    defaultValue={
                      profileData.lsa_date
                        ? moment(profileData.lsa_date).format(
                            dateTimeConstants.DATE_FORMAT
                          )
                        : null
                    }
                    rules={{
                      required: "Date is required",
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date.endOf("day").isAfter(moment().endOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : ""
                          )
                        }
                        maxDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.lsa_date && (
                    <p className="error-message">{errors.lsa_date.message}</p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>IFCA SRB</b>
            </span>
            <span>
              {getIfcaStatusText(
                profileData.ifca_srb_status,
                profileData.ifca_srb_date
              )}
            </span>
            <EditProfile
              label="IFCA SRB"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>Status</label>
                  <select
                    {...register("ifca_srb_status", {
                      required: "Status is required",
                    })}
                    defaultValue={profileData.ifca_srb_status}
                    className={
                      errors.ifca_srb_status && "profile-form-field-error"
                    }
                  >
                    <option value="">Select Status</option>
                    <option value="1">Sent</option>
                    <option value="2">Approved</option>
                  </select>
                  {errors.ifca_srb_status && (
                    <p className="error-message">
                      {errors.ifca_srb_status.message}
                    </p>
                  )}

                  <label>Date</label>
                  <Controller
                    name="ifca_srb_date"
                    control={control}
                    defaultValue={
                      profileData.ifca_srb_date
                        ? moment(profileData.ifca_srb_date).format(
                            dateTimeConstants.DATE_FORMAT
                          )
                        : null
                    }
                    rules={{
                      required: "Date is required",
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date.endOf("day").isAfter(moment().endOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : ""
                          )
                        }
                        maxDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.ifca_srb_date && (
                    <p className="error-message">
                      {errors.ifca_srb_date.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default IfcaContractComponent;

import React from "react";

import { EditProfile } from "./EditProfile";

import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";

import { formatDate } from "_helpers/helpers";
import { dateTimeConstants } from "_constants";
import moment from "moment";

const AssignedDatesComponent = ({
  profileData,
  refetchUser,
  isOpen,
  toggleAccordion,
}) => {
  return (
    <div
      id="assigned-dates"
      className={`profile-data__drops ${isOpen ? "is-active" : ""}`}
    >
      <header onClick={() => toggleAccordion("#assigned-dates")}>
        <button type="button">
          <h3>Assigned Dates</h3>
        </button>
      </header>
      <div className="profile-data__drops-cnt">
        <ul role="list">
          <li role="listitem">
            <span>
              <b>Security Code</b>
            </span>
            <span>{formatDate(profileData.useritemdates?.security_code_assigned)}</span>
            <EditProfile
              label="Security Code"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>Security Code</label>
                  <Controller
                    name="security_code_assigned"
                    control={control}
                    defaultValue={
                      profileData.useritemdates?.security_code_assigned
                        ? moment(profileData.useritemdates?.security_code_assigned).format(
                            dateTimeConstants.DATE_FORMAT
                          )
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date.endOf("day").isAfter(moment().endOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : ""
                          )
                        }
                        maxDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select Security Code Assigned Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.security_code_assigned && (
                    <p className="error-message">
                      {errors.security_code_assigned.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>Credit Card</b>
            </span>
            <span>{formatDate(profileData.useritemdates?.credit_card_assigned)}</span>
            <EditProfile
              label="Credit Card"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>Credit Card</label>
                  <Controller
                    name="credit_card_assigned"
                    control={control}
                    defaultValue={
                      profileData.useritemdates?.credit_card_assigned
                        ? moment(profileData.useritemdates?.credit_card_assigned).format(
                            dateTimeConstants.DATE_FORMAT
                          )
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date.endOf("day").isAfter(moment().endOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : ""
                          )
                        }
                        maxDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select Credit Card Assigned Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.credit_card_assigned && (
                    <p className="error-message">
                      {errors.credit_card_assigned.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>Fuel Pin</b>
            </span>
            <span>{formatDate(profileData.useritemdates?.fuel_pin_assigned)}</span>
            <EditProfile
              label="Fuel Pin"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>Fuel Pin</label>
                  <Controller
                    name="fuel_pin_assigned"
                    control={control}
                    defaultValue={
                      profileData.useritemdates?.fuel_pin_assigned
                        ? moment(profileData.useritemdates?.fuel_pin_assigned).format(
                            dateTimeConstants.DATE_FORMAT
                          )
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date.endOf("day").isAfter(moment().endOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : ""
                          )
                        }
                        maxDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select Fuel Pin Assigned Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.fuel_pin_assigned && (
                    <p className="error-message">
                      {errors.fuel_pin_assigned.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>Key Fob</b>
            </span>
            <span>{(profileData.useritemdates?.key_fob ? profileData.useritemdates?.key_fob + ': ' : '') + (profileData.useritemdates?.key_fob_assigned ? formatDate(profileData.useritemdates?.key_fob_assigned) : '-')}</span>
            <EditProfile
              label="Key Fob"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>Key Fob #</label>
                  <input
                    {...register("key_fob", {
                      maxLength: {
                        value: 10,
                        message:
                          "Length exceeds the maximum limit of 10 characters.",
                      },
                    })}
                    defaultValue={profileData.useritemdates?.key_fob}
                    className={errors.key_fob && "profile-form-field-error"}
                  />
                  {errors.key_fob && (
                    <p className="error-message">{errors.key_fob.message}</p>
                  )}
                  <label>Key Fob Assigned Date</label>
                  <Controller
                    name="key_fob_assigned"
                    control={control}
                    defaultValue={
                      profileData.useritemdates?.key_fob_assigned
                        ? moment(profileData.useritemdates?.key_fob_assigned).format(
                            dateTimeConstants.DATE_FORMAT
                          )
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date.endOf("day").isAfter(moment().endOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : ""
                          )
                        }
                        maxDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select Key Fob Assigned Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.key_fob_assigned && (
                    <p className="error-message">
                      {errors.key_fob_assigned.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AssignedDatesComponent;

import React, { useState } from "react";
import NumberFormat from "react-number-format";
import DatePicker from "react-datepicker";
import { Controller } from "react-hook-form";

import { config } from "_helpers";
import {
  preventNegativeValueInDocs,
  formatSSN,
  formatPhone,
} from "_helpers/helpers";

import { dateTimeConstants } from "_constants";

import { EditProfile } from "./EditProfile";

import moment from "moment";

const MedicalInformationComponent = ({
  profileData,
  refetchUser,
  isOpen,
  toggleAccordion,
}) => {
  return (
    <div
      
      id="medical-information"
      className={`profile-data__drops ${isOpen ? "is-active" : ""}`}
    >
      <header onClick={() => toggleAccordion("#medical-information")}>
        <button type="button">
          <h3>Medical Information</h3>
        </button>
      </header>
      <div className="profile-data__drops-cnt">
        <ul role="list">
          <li role="listitem">
            <span>
              <b>Allergies</b>
            </span>
            <span>{profileData.allergies}</span>
            <EditProfile
              label="Allergies"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors) => (
                <>
                  <label>Allergies</label>
                  <input
                    {...register("allergies", {
                      maxLength: {
                        value: 100,
                        message:
                          "Length exceeds the maximum limit of 100 characters.",
                      },
                    })}
                    defaultValue={profileData.allergies}
                    className={errors.allergies && "profile-form-field-error"}
                  />
                  {errors.allergies && (
                    <p className="error-message">{errors.allergies.message}</p>
                  )}
                </>
              )}
            </EditProfile>
          </li>
          <li role="listitem">
            <span>
              <b>Prescriptions</b>
            </span>
            <span>{profileData.prescriptions}</span>
            <EditProfile
              label="Prescriptions"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors) => (
                <>
                  <label>Prescriptions</label>
                  <input
                    {...register("prescriptions", {
                      maxLength: {
                        value: 100,
                        message:
                          "Length exceeds the maximum limit of 100 characters.",
                      },
                    })}
                    defaultValue={profileData.prescriptions}
                    className={
                      errors.prescriptions && "profile-form-field-error"
                    }
                  />
                  {errors.prescriptions && (
                    <p className="error-message">
                      {errors.prescriptions.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MedicalInformationComponent;

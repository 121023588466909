import React from "react";

import { convertDateTimeToPST } from "_helpers/helpers";

import { useUpdateProfile } from "./EditProfile";

const WorkersCompensationComponent = ({
  profileData,
  refetchUser,
  isOpen,
  toggleAccordion,
}) => {
  const [comp, setComp] = React.useState("");
  const [displayAllComp, setDisplayAllComp] = React.useState(false);

  const handleCompChange = (event) => {
    setComp(event.target.value);
  };

  const mutation = useUpdateProfile(refetchUser, () => {
    setComp("");
  });

  const toggleAllCompDisplay = () => {
    setDisplayAllComp(!displayAllComp);
  };

  const saveComp = () => {
    let data = new FormData();
    data.append("worker_compensation", comp.trim());
    mutation.mutate({ userId: profileData.id, formData: data });
  };

  return (
    <div
      id="workers-compensation"
      className={`profile-data__drops profile-data__drops--notes ${
        isOpen ? "is-active" : ""
      }`}
    >
      <header onClick={() => toggleAccordion("#workers-compensation")}>
        <button type="button">
          <h3>Workers' Compensation</h3>
        </button>
      </header>
      <div className="profile-data__drops-cnt">
        <div className="profile-data__form-wrap is-active">
          <textarea
            maxLength="1200"
            type="text"
            autoComplete="off"
            placeholder="Add your note here"
            value={comp}
            onChange={handleCompChange}
          ></textarea>
          {comp.trim().length ? (
            <footer>
              <button type="button" className="l-btn l-btn--close">
                Cancel
              </button>
              <button onClick={saveComp} type="button" className="l-btn">
                Save
              </button>
            </footer>
          ) : (
            <></>
          )}
        </div>
        <ul role="list">
          {profileData.worker_compensation.length &&
          profileData.worker_compensation[0] ? (
            <li role="listitem">
              <div className="profile-data__note">
                <header>
                  <h4>
                    Note
                    {profileData.worker_compensation[0].author_user ? (
                      " by " +
                      profileData.worker_compensation[0].author_user.full_name
                    ) : (
                      <></>
                    )}
                  </h4>
                  <div className="profile-data__note-dates">
                    <span>
                      {profileData.worker_compensation[0].author_user
                        ? convertDateTimeToPST(
                            profileData.worker_compensation[0].created_at
                          )
                        : ""}
                    </span>
                  </div>
                </header>
                <p>{profileData.worker_compensation[0].note}</p>
              </div>
            </li>
          ) : (
            <></>
          )}
          {displayAllComp &&
            profileData.worker_compensation.length > 1 &&
            profileData.worker_compensation.slice(1).map((noteItem, index) => (
              <li role="listitem">
                <div className="profile-data__note">
                  <header>
                    <h4>
                      Note
                      {noteItem.author_user ? (
                        " by " + noteItem.author_user.full_name
                      ) : (
                        <></>
                      )}
                    </h4>
                    <div className="profile-data__note-dates">
                      <span>
                        {noteItem.author_user
                          ? convertDateTimeToPST(noteItem.created_at)
                          : ""}
                      </span>
                    </div>
                  </header>
                  <p>{noteItem.note}</p>
                </div>
              </li>
            ))}
        </ul>
      </div>
      <footer
        className={
          profileData.worker_compensation.length > 1 ? "footer-border" : ""
        }
      >
        {profileData.worker_compensation.length > 1 && (
          <button onClick={toggleAllCompDisplay} type="button">
            Show {displayAllComp ? "Less" : "All"}
          </button>
        )}
      </footer>
    </div>
  );
};

export default WorkersCompensationComponent;

import React from "react";

import { EditProfile } from "./EditProfile";

import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";

import { formatDate } from "_helpers/helpers";
import { dateTimeConstants } from "_constants";
import moment from "moment";

const AdministrationComponent = ({
  profileData,
  refetchUser,
  isOpen,
  toggleAccordion,
}) => {
  return (
    <div
      id="administration"
      className={`profile-data__drops ${isOpen ? "is-active" : ""}`}
    >
      <header onClick={() => toggleAccordion("#administration")}>
        <button type="button">
          <h3>Administration</h3>
        </button>
      </header>
      <div className="profile-data__drops-cnt">
        <ul role="list">
          <li role="listitem">
            <span>
              <b>E-Verify Date</b>
            </span>
            <span>{formatDate(profileData.everify_date)}</span>
            <EditProfile
              label="E-Verify Date"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>E-Verify Date</label>
                  <Controller
                    name="everify_date"
                    control={control}
                    defaultValue={
                      profileData.everify_date
                        ? moment(profileData.everify_date).format(
                            dateTimeConstants.DATE_FORMAT
                          )
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date.endOf("day").isAfter(moment().endOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : ""
                          )
                        }
                        maxDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select E-Verify Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.everify_date && (
                    <p className="error-message">
                      {errors.everify_date.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>401k Eligibility Status and Date</b>
            </span>
            <span>
              {profileData.dob &&
              moment().diff(moment(profileData.dob), "years") >= 21 &&
              profileData.everify_date &&
              moment().diff(moment(profileData.everify_date), "years") >= 1
                ? "Eligible as of " +
                  formatDate(moment(profileData.everify_date).add(1, "year"))
                : "-"}
            </span>
          </li>

          <li>
            <span>
              <b>Sexual Harassment Certification</b>
            </span>
            <span>
              {profileData.sexual_harassment_certification
                ? "Certified on " +
                  formatDate(profileData.sexual_harassment_certification)
                : ""}
              <br />
              {profileData.sexual_harassment_expiry
                ? (moment(profileData.sexual_harassment_expiry) < moment()
                    ? "Expired "
                    : "Expires ") +
                  "on " +
                  formatDate(profileData.sexual_harassment_expiry)
                : ""}
            </span>
            <EditProfile
              label="Sexual Harassment Certification Dates"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>Sexual Harassment Certification Date</label>
                  <Controller
                    name="sexual_harassment_certification"
                    control={control}
                    defaultValue={
                      profileData.sexual_harassment_certification
                        ? moment(
                            profileData.sexual_harassment_certification
                          ).format(dateTimeConstants.DATE_FORMAT)
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date.endOf("day").isAfter(moment().endOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : ""
                          )
                        }
                        maxDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select Certification Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.sexual_harassment_certification && (
                    <p className="error-message">
                      {errors.sexual_harassment_certification.message}
                    </p>
                  )}

                  <label>Sexual Harassment Certification Expiration Date</label>
                  <Controller
                    name="sexual_harassment_expiry"
                    control={control}
                    defaultValue={
                      profileData.sexual_harassment_expiry
                        ? moment(profileData.sexual_harassment_expiry).format(
                            dateTimeConstants.DATE_FORMAT
                          )
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date
                                .startOf("day")
                                .isBefore(moment().startOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : ""
                          )
                        }
                        minDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select Expiration Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.sexual_harassment_expiry && (
                    <p className="error-message">
                      {errors.sexual_harassment_expiry.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AdministrationComponent;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dashboardActions } from "_actions";
import { dashboardService } from "_services";
import AsyncSelect from "react-select/async";
import { redirectToPdf } from "_helpers/redirectToPdf";
import DolApprovedDriverReport from "./DolApprovedDriverReport";
import { dolApproved } from "_helpers/reduxSelector";

const Sort = {
  Asc: "asc",
  Dsc: "desc",
};

const ReportType = {
  Csv: "csv",
  Pdf: "pdf",
};

const options = [
    { label: 'All', value: '' },
    { label: 'Application Sent', value: '1' },
    { label: 'Approved', value: '2' },
    { label: 'Rejected', value: '3' },
  ];

const selectStyles = {
  control: (css) => ({
    ...css,
    width: MENU_WIDTH || "auto",
    opacity: MENU_WIDTH ? 1 : 0,
    zIndex: 7,
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  menu: (provided) => ({
    ...provided,
    opacity: MENU_WIDTH ? 1 : 0,
    zIndex: 9999,
  }),
  option: (css) => ({ ...css, paddingRight: 36 + 8, zIndex: 9999 }),
};

const MENU_WIDTH = 300;

function DolApprovedDriverReportContainer(props) {
  const dispatch = useDispatch();

  const reportData = useSelector((state) => dolApproved(state));
  const [globalSort, setGlobalSort] = useState("name");
  const [globalOrder, setGlobalOrder] = useState("asc");
  const [selectedStatus, setSelectedStatus] = useState({});

  const fetchDolApprovedDriverReport = (newPage, sort, order) => {
    const data = new FormData();
    data.append("page", newPage || 0);
    data.append("sort", sort || "name");
    data.append("order", order || "asc");
    data.append("status", selectedStatus?.value || "");
    dispatch(dashboardActions.fetchDolApprovedDriverReport(data));
  };

  const setSortParams = (sort, order) => {
    const sortOrder = order ? Sort.Asc : Sort.Dsc;
    setGlobalSort(sort);
    setGlobalOrder(sortOrder);
  };

  const onExport = () => {
    const data = new FormData();
    data.append("sort", globalSort || "incident");
    data.append("status", selectedStatus?.value || "");
    data.append("order", globalOrder || "asc");
    data.append("report_type", ReportType.Csv);
    dashboardService.exportDolApprovedReportCSV(data);
  };

  const onPrint = () => {
    const data = new FormData();
    data.append("sort", globalSort || "incident");
    data.append("status", selectedStatus?.value || "");
    data.append("order", globalOrder || "asc");
    data.append("report_type", ReportType.Pdf);
    dashboardService
      .exportDolApprovedReportPDF(data)
      .then((res) => redirectToPdf(res));
  };


  useEffect(() => {
    fetchDolApprovedDriverReport(0, globalSort, globalOrder);
  }, [selectedStatus]);

  return (
    <div>
      <div className={props.isReportMenu ? "" : ""}>
        <div className="white">
          <div className="inner_container col-lg-12 crew_wrapper back_to_dashboard_wrapper">
            <div id="chart">
              <div className="report_tbl_head sold_rpt_mbl_flx">
                <h5 className="graph_header">Dol Application Status Report</h5>
                <div
                  style={{ gap: `${props.isReportMenu ? "10px" : "0px"}` }}
                  className="report_head_btn_grp"
                >
                  <div className="report_multi_btn_grp milage__head-wrap">
                    <div className="select-applicants-report">
                      <AsyncSelect
                        cacheOptions
                        defaultOptions={options}
                        onChange={(selection) => setSelectedStatus(selection)}
                        isClearable
                        isSearchable={false}
                        styles={selectStyles}
                        placeholder={"Choose DOL Approval Status"}
                        className="basic-multi-select"
                        classNamePrefix="select"
                      />
                    </div>
                    <div className="milage__btn-wraper">
                      <button
                        className="print_button"
                        style={{ marginRight: "10px" }}
                        onClick={() => {
                          onExport();
                        }}
                      >
                        Export
                      </button>
                      <button
                        className="print_button"
                        onClick={() => {
                          onPrint();
                        }}
                      >
                        Print
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <DolApprovedDriverReport
                data={reportData?.data || []}
                lastPage={reportData?.last_page}
                fetchDolApprovedDriverReport={fetchDolApprovedDriverReport}
                setSortParams={setSortParams}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DolApprovedDriverReportContainer;

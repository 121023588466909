import React from "react";

import { EditProfile } from "./EditProfile";

import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";

import { formatDate } from "_helpers/helpers";
import { dateTimeConstants } from "_constants";
import moment from "moment";

const nccStatus = {
  SENT: 1,
  APPROVED: 2,
};

const NationalCrewComponent = ({
  profileData,
  refetchUser,
  isOpen,
  toggleAccordion,
}) => {
  function getNCCStatusText(nccStat, nccDate) {
    if (!nccStat) return "";

    let statusText = "";
    if (nccStat === nccStatus.SENT) {
      statusText = "Sent";
    } else if (nccStat === nccStatus.APPROVED) {
      statusText = "Approved";
    }

    return `${statusText} on ${formatDate(nccDate)}`;
  }

  return (
    <div
      id="national-crew"
      className={`profile-data__drops ${isOpen ? "is-active" : ""}`}
    >
      <header onClick={() => toggleAccordion("#national-crew")}>
        <button type="button">
          <h3>National Crew</h3>
        </button>
      </header>
      <div className="profile-data__drops-cnt">
        <ul role="list">
          <li role="listitem">
            <span>
              <b>NCC FFT1</b>
            </span>
            <span>
              {getNCCStatusText(
                profileData.ncc_fft1_status,
                profileData.ncc_fft1_date
              )}
            </span>
            <EditProfile
              label="NCC FFT1"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>Status</label>
                  <select
                    {...register("ncc_fft1_status", {
                      required: "Status is required",
                    })}
                    defaultValue={profileData.ncc_fft1_status}
                    className={
                      errors.ncc_fft1_status && "profile-form-field-error"
                    }
                  >
                    <option value="">Select Status</option>
                    <option value="1">Sent</option>
                    <option value="2">Approved</option>
                  </select>
                  {errors.ncc_fft1_status && (
                    <p className="error-message">
                      {errors.ncc_fft1_status.message}
                    </p>
                  )}

                  <label>Date</label>
                  <Controller
                    name="ncc_fft1_date"
                    control={control}
                    defaultValue={
                      profileData.ncc_fft1_date
                        ? moment(profileData.ncc_fft1_date).format(
                            dateTimeConstants.DATE_FORMAT
                          )
                        : null
                    }
                    rules={{
                      required: "Date is required",
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date.endOf("day").isAfter(moment().endOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : ""
                          )
                        }
                        maxDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.ncc_fft1_date && (
                    <p className="error-message">
                      {errors.ncc_fft1_date.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>NCC CRWB</b>
            </span>
            <span>
              {getNCCStatusText(
                profileData.ncc_crwb_status,
                profileData.ncc_crwb_date
              )}
            </span>
            <EditProfile
              label="NCC CRWB"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>Status</label>
                  <select
                    {...register("ncc_crwb_status", {
                      required: "Status is required",
                    })}
                    defaultValue={profileData.ncc_crwb_status}
                    className={
                      errors.ncc_crwb_status && "profile-form-field-error"
                    }
                  >
                    <option value="">Select Status</option>
                    <option value="1">Sent</option>
                    <option value="2">Approved</option>
                  </select>
                  {errors.ncc_crwb_status && (
                    <p className="error-message">
                      {errors.ncc_crwb_status.message}
                    </p>
                  )}

                  <label>Date</label>
                  <Controller
                    name="ncc_crwb_date"
                    control={control}
                    defaultValue={
                      profileData.ncc_crwb_date
                        ? moment(profileData.ncc_crwb_date).format(
                            dateTimeConstants.DATE_FORMAT
                          )
                        : null
                    }
                    rules={{
                      required: "Date is required",
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date.endOf("day").isAfter(moment().endOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : ""
                          )
                        }
                        maxDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.ncc_crwb_date && (
                    <p className="error-message">
                      {errors.ncc_crwb_date.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>VIPR FFT1</b>
            </span>
            <span>
              {getNCCStatusText(
                profileData.vipr_fft1_status,
                profileData.vipr_fft1_date
              )}
            </span>
            <EditProfile
              label="VIPR FFT1"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>Status</label>
                  <select
                    {...register("vipr_fft1_status", {
                      required: "Status is required",
                    })}
                    defaultValue={profileData.vipr_fft1_status}
                    className={
                      errors.vipr_fft1_status && "profile-form-field-error"
                    }
                  >
                    <option value="">Select Status</option>
                    <option value="1">Sent</option>
                    <option value="2">Approved</option>
                  </select>
                  {errors.vipr_fft1_status && (
                    <p className="error-message">
                      {errors.vipr_fft1_status.message}
                    </p>
                  )}

                  <label>Date</label>
                  <Controller
                    name="vipr_fft1_date"
                    control={control}
                    defaultValue={
                      profileData.vipr_fft1_date
                        ? moment(profileData.vipr_fft1_date).format(
                            dateTimeConstants.DATE_FORMAT
                          )
                        : null
                    }
                    rules={{
                      required: "Date is required",
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date.endOf("day").isAfter(moment().endOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : ""
                          )
                        }
                        maxDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.vipr_fft1_date && (
                    <p className="error-message">
                      {errors.vipr_fft1_date.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>VIPR ENGB</b>
            </span>
            <span>
              {getNCCStatusText(
                profileData.vipr_engb_status,
                profileData.vipr_engb_date
              )}
            </span>
            <EditProfile
              label="VIPR ENGB"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>Status</label>
                  <select
                    {...register("vipr_engb_status", {
                      required: "Status is required",
                    })}
                    defaultValue={profileData.vipr_engb_status}
                    className={
                      errors.vipr_engb_status && "profile-form-field-error"
                    }
                  >
                    <option value="">Select Status</option>
                    <option value="1">Sent</option>
                    <option value="2">Approved</option>
                  </select>
                  {errors.vipr_engb_status && (
                    <p className="error-message">
                      {errors.vipr_engb_status.message}
                    </p>
                  )}

                  <label>Date</label>
                  <Controller
                    name="vipr_engb_date"
                    control={control}
                    defaultValue={
                      profileData.vipr_engb_date
                        ? moment(profileData.vipr_engb_date).format(
                            dateTimeConstants.DATE_FORMAT
                          )
                        : null
                    }
                    rules={{
                      required: "Date is required",
                    }}
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date.endOf("day").isAfter(moment().endOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : ""
                          )
                        }
                        maxDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.vipr_engb_date && (
                    <p className="error-message">
                      {errors.vipr_engb_date.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NationalCrewComponent;

import React, { useEffect, useState } from "react";
import "./Profile.scss";
import { config } from "_helpers";
import { EditProfile } from "./EditProfile";
import OverviewComponent from "./OverviewComponent";
import MedicalInformationComponent from "./MedicalInformationComponent";
import ContactInformationComponent from "./ContactInformationComponent";
import AffirmativeActionComponent from "./AffirmativeActionComponent";
import MerchandiseInformationComponent from "./MerchandiseInformationComponent";
import WorkersCompensationComponent from "./WorkersCompensationComponent";
import NotesComponent from "./NotesComponent";
import { userStatus } from "_constants";
import { useQuery } from "react-query";
import { profileService } from "_services/profile.service";
import DriverQualificationsComponent from "./DriverQualificationsComponent";
import AdministrationComponent from "./AdministrationComponent";
import { profile } from "_reducers/profile.reducer";
import QualificationsComponent from "./QualificationsComponent";
import IfcaContractComponent from "./IfcaContractComponent";
import NationalCrewComponent from "./NationalCrewComponent";
import InventoryAssignedComponent from "./InventoryAssignedComponent";
import AssignedDatesComponent from "./AssignedDatesComponent";
import CompletedDatesComponent from "./CompletedDatesComponent";
import ExpirationDatesComponent from "./ExpirationDatesComponent";
import VerifiedDocumentsComponent from "./VerifiedDocumentsComponent";
import EmploymentHistoryComponent from "./EmploymentHistoryComponent";

const ViewProfile = ({ userHash, authUser, permList, changeTab, reloadKey }) => {
  // Fetch dropdown data
  const { data: dropdownData } = useQuery(
    "dropdownData",
    profileService.fetchDropdownData
  );

  useEffect(() => {
    // Fetch data or trigger update whenever reloadKey changes
    refetchUser();
  }, [reloadKey]);

  const [activeDivision, setActiveDivision] = useState("#overview");
  const [expandedComponents, setExpandedComponents] = useState([]);

  const divisions = [
    { href: "#overview", title: "Overview", text: "Overview" },
    {
      href: "#contact-information",
      title: "Contact Information",
      text: "Contact Information",
    },
    { href: "#notes", title: "Notes", text: "Notes" },
    {
      href: "#workers-compensation",
      title: "Workers' Compensation",
      text: "Workers' Compensation",
    },
    {
      href: "#medical-information",
      title: "Medical Information",
      text: "Medical Information",
    },
    {
      href: "#affirmative-action",
      title: "Affirmative Action",
      text: "Affirmative Action",
    },
    {
      href: "#merchandise-information",
      title: "Merchandise Information",
      text: "Merchandise Information",
    },
    {
      href: "#driver-qualifications",
      title: "Driver Qualifications",
      text: "Driver Qualifications",
    },
    {
      href: "#administration",
      title: "Administration",
      text: "Administration",
    },
    {
      href: "#qualifications",
      title: "Qualifications",
      text: "Qualifications",
    },
    { href: "#ifca-contract", title: "IFCA Contract", text: "IFCA Contract" },
    { href: "#national-crew", title: "National Crew", text: "National Crew" },
    {
      href: "#inventory-assigned",
      title: "Inventory Assigned",
      text: "Inventory Assigned",
    },
    {
      href: "#assigned-dates",
      title: "Assigned Dates",
      text: "Assigned Dates",
    },
    {
      href: "#completed-dates",
      title: "Completed Dates",
      text: "Completed Dates",
    },
    {
      href: "#expiration-dates",
      title: "Expiration Dates",
      text: "Expiration Dates",
    },
    {
      href: "#verified-documents",
      title: "Verified Documents",
      text: "Verified Documents",
    },
    {
      href: "#employment-history",
      title: "Employment History",
      text: "Employment History",
    },
  ];

  const changeActiveDivision = (divisionHref) => {
    setActiveDivision(divisionHref);

    // Scroll to the corresponding section with smooth scrolling and offset
    const targetSection = document.querySelector(divisionHref);
    const offset = 60; // Height of the top bar or header

    if (targetSection) {
      const sectionPosition =
        targetSection.getBoundingClientRect().top + window.pageYOffset - offset;
      window.scrollTo({
        top: sectionPosition,
        behavior: "smooth",
      });
    }
    setExpandedComponents((prev) =>
      prev.includes(divisionHref) ? prev : [...prev, divisionHref]
    );
  };

  const collapseAllComponents = () => {
    setActiveDivision("#overview");
    setExpandedComponents([]);
  };

  const expandAllComponents = () => {
    setActiveDivision("#overview");
    setExpandedComponents(divisions.map((division) => division.href));
  };

  const toggleAccordion = (divisionHref) => {
    setExpandedComponents((prev) =>
      prev.includes(divisionHref)
        ? prev.filter((item) => item !== divisionHref)
        : [...prev, divisionHref]
    );
  };

  // Fetch profile data, passing the function reference, not the result of the function call
  const {
    data: profileData,
    refetch: refetchUser,
    isSuccess: isProfileDataFetched,
  } = useQuery(
    ["userData", userHash], // Unique key including userHash to prevent conflicts
    () => profileService.getUser(userHash), // Pass function reference, not the result
    {
      enabled: !!userHash, // Ensure query only runs when userHash is available
    }
  );

  let profileUserName = "";
  let profilePic = "";
  const { data: trainingData } = useQuery(
    ["trainingUserData", profileData?.id],
    () => profileService.getTrainingData(profileData?.usertraining?.id),
    {
      enabled: isProfileDataFetched && !!profileData?.usertraining?.id,
    }
  );

  const { data: inventoryAssignedData } = useQuery(
    ["inventoryAssignedUserData", profileData?.hash],
    () => profileService.getInventoryAssignedData(profileData?.hash),
    {
      enabled: isProfileDataFetched && !!profileData?.hash,
    }
  );

  const { data: historyData } = useQuery(
    ["historyUserData", profileData?.hash],
    () => profileService.getEmploymentHistoryData(profileData?.id),
    {
      enabled: isProfileDataFetched && !!profileData?.hash,
    }
  );

  if (profileData) {
    // Image
    profilePic =
      config.apiUrl + "/" + profileData.profile_photo ?? config.altImg;

    // User name
    profileUserName =
      profileData.first_name &&
      profileData.first_name +
        " " +
        (profileData.middle_name ? profileData.middle_name + " " : "") +
        profileData.last_name;
  }

  const onImageError = (e) => {
    e.target.setAttribute("src", config.apiUrl + "/" + config.altImg);
  };

  return (
    <div className="ft profile-data">
      <nav className="profile-data__nav">
        <ul role="list" className="profile-divisions">
          {divisions.map((division, index) => (
            <li
              key={index}
              className={division.href === activeDivision ? "active" : ""}
            >
              <a
                onClick={() => changeActiveDivision(division.href)}
                title={division.title}
                className="division-button"
              >
                {division.text}
              </a>
            </li>
          ))}
        </ul>
      </nav>
      <div className="profile-data__cnt">
        {profileData && dropdownData && (
          <>
            {/* Overview Division */}
            <OverviewComponent
              profileData={profileData}
              profileUserName={profileUserName}
              dropdownData={dropdownData}
              refetchUser={refetchUser}
              permList={permList}
            />
            <div className="profile-data__col">
              <header>
                <button
                  type="button"
                  onClick={
                    expandedComponents.length
                      ? collapseAllComponents
                      : expandAllComponents
                  }
                >
                  {expandedComponents.length ? "Collapse" : "Expand"} All
                </button>
              </header>
              <ContactInformationComponent
                profileData={profileData}
                refetchUser={refetchUser}
                isOpen={expandedComponents.includes("#contact-information")}
                toggleAccordion={toggleAccordion}
              />
              <NotesComponent
                profileData={profileData}
                refetchUser={refetchUser}
                isOpen={expandedComponents.includes("#notes")}
                toggleAccordion={toggleAccordion}
              />
              <WorkersCompensationComponent
                profileData={profileData}
                refetchUser={refetchUser}
                isOpen={expandedComponents.includes("#workers-compensation")}
                toggleAccordion={toggleAccordion}
              />
              <MedicalInformationComponent
                profileData={profileData}
                refetchUser={refetchUser}
                isOpen={expandedComponents.includes("#medical-information")}
                toggleAccordion={toggleAccordion}
              />
              <AffirmativeActionComponent
                profileData={profileData}
                dropdownData={dropdownData}
                refetchUser={refetchUser}
                isOpen={expandedComponents.includes("#affirmative-action")}
                toggleAccordion={toggleAccordion}
              />
              <MerchandiseInformationComponent
                profileData={profileData}
                dropdownData={dropdownData}
                refetchUser={refetchUser}
                isOpen={expandedComponents.includes("#merchandise-information")}
                toggleAccordion={toggleAccordion}
              />
              <DriverQualificationsComponent
                profileData={profileData}
                refetchUser={refetchUser}
                isOpen={expandedComponents.includes("#driver-qualifications")}
                toggleAccordion={toggleAccordion}
              />
              <AdministrationComponent
                profileData={profileData}
                refetchUser={refetchUser}
                isOpen={expandedComponents.includes("#administration")}
                toggleAccordion={toggleAccordion}
              />
              <QualificationsComponent
                profileData={profileData}
                dropdownData={dropdownData}
                trainingData={trainingData?.data?.form}
                isOpen={expandedComponents.includes("#qualifications")}
                refetchUser={refetchUser}
                toggleAccordion={toggleAccordion}
                changeTab={changeTab}
              />
              <IfcaContractComponent
                profileData={profileData}
                refetchUser={refetchUser}
                isOpen={expandedComponents.includes("#ifca-contract")}
                toggleAccordion={toggleAccordion}
              />
              <NationalCrewComponent
                profileData={profileData}
                refetchUser={refetchUser}
                isOpen={expandedComponents.includes("#national-crew")}
                toggleAccordion={toggleAccordion}
              />
              <InventoryAssignedComponent
                profileData={profileData}
                inventoryData={inventoryAssignedData?.data}
                isOpen={expandedComponents.includes("#inventory-assigned")}
                toggleAccordion={toggleAccordion}
              />
              <h3>Important Dates</h3>
              <AssignedDatesComponent
                profileData={profileData}
                refetchUser={refetchUser}
                isOpen={expandedComponents.includes("#assigned-dates")}
                toggleAccordion={toggleAccordion}
              />
              <CompletedDatesComponent
                profileData={profileData}
                refetchUser={refetchUser}
                isOpen={expandedComponents.includes("#completed-dates")}
                toggleAccordion={toggleAccordion}
              />
              <ExpirationDatesComponent
                profileData={profileData}
                refetchUser={refetchUser}
                isOpen={expandedComponents.includes("#expiration-dates")}
                toggleAccordion={toggleAccordion}
              />
              <VerifiedDocumentsComponent
                profileData={profileData}
                refetchUser={refetchUser}
                isOpen={expandedComponents.includes("#verified-documents")}
                toggleAccordion={toggleAccordion}
              />
              <EmploymentHistoryComponent
                profileData={profileData}
                historyData={historyData?.data}
                isOpen={expandedComponents.includes("#employment-history")}
                toggleAccordion={toggleAccordion}
              />
            </div>
          </>
        )}
      </div>

      {/* Profile Data Card */}
      <div className="profile-data__card-wrap">
        {profileData && (
          <div className="profile-data__card">
            <figure
              className={
                profileData.is_locked === 1
                  ? "Locked"
                  : profileData.status === userStatus.Guest
                  ? "Guest"
                  : profileData.active
              }
            >
              <img
                alt={
                  profileData.first_name &&
                  profileData.first_name + " " + profileData.last_name
                }
                src={profilePic}
                onError={onImageError}
              />
            </figure>
            <h3>{profileUserName}</h3>
            <ul role="list">
              <li role="listitem">{profileData.user_type}</li>
              <li role="listitem">{profileData.crew_name}</li>
              <li role="listitem">
                Firestorm ID #: <span>{profileData.fire_id ?? "-"}</span>
              </li>
              <li role="listitem">
                ICA #: <span> {profileData.ica_number ?? "-"}</span>
              </li>
              <li role="listitem">
                Primary Crew:{" "}
                <span>{profileData.primary_crew_name ?? "-"}</span>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewProfile;

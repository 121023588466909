import React from "react";

import { EditProfile } from "./EditProfile";

import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";

import { formatDate } from "_helpers/helpers";
import { dateTimeConstants } from "_constants";
import moment from "moment";

const CompletedDatesComponent = ({
  profileData,
  refetchUser,
  isOpen,
  toggleAccordion,
}) => {
  return (
    <div
      id="completed-dates"
      className={`profile-data__drops ${isOpen ? "is-active" : ""}`}
    >
      <header onClick={() => toggleAccordion("#completed-dates")}>
        <button type="button">
          <h3>Completed Dates</h3>
        </button>
      </header>
      <div className="profile-data__drops-cnt">
        <ul role="list">
          <li role="listitem">
            <span>
              <b>RT-130</b>
            </span>
            <span>{formatDate(profileData.useritemdates?.rt_130_expiry)}</span>
            <EditProfile
              label="RT-130"
              profileData={profileData}
              refetchUser={refetchUser}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>RT-130</label>
                  <Controller
                    name="rt_130_expiry"
                    control={control}
                    defaultValue={
                      profileData.useritemdates?.rt_130_expiry
                        ? moment(profileData.useritemdates?.rt_130_expiry).format(
                            dateTimeConstants.DATE_FORMAT
                          )
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date.endOf("day").isAfter(moment().endOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : ""
                          )
                        }
                        maxDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select RT 130 Expiry Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.rt_130_expiry && (
                    <p className="error-message">
                      {errors.rt_130_expiry.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default CompletedDatesComponent;
